var $ = require('jquery');

var env = new function() {
    var self = this;
    
    var device = self.device = {};
    var browser = self.browser = {};
    var platform = self.platform = {};
    var version = self.version = {};
    var event = self.event ={};
    var css = self.css = {};
    var webgl = self.webgl = false;
    
    var cache = {};
    var ua;

    var init = function (opts) {
        initCache();
		
        checkDevice();
		checkPlatform();        
        checkNavigator();
        checkVendorPrefix();
        checkVideoSupport();
        checkVersion();
        checkEvents();
        checkCss();
        // checkWebGL();
        addConsole();

        addClasses();
    };

    var initCache = function(){
        ua = navigator.userAgent.toLowerCase();
        cache.html = $("html");
    };

    var checkDevice = function(){
        device.ipad = ua.match(/iPad/i) != null;
        device.iphone = ua.match(/iPhone/i) != null || ua.match(/iPod/i) != null;
        device.mobile = ua.match(/mobile/i) != null;
        device.tablet =  ((device.ipad && ! device.iphone) || (ua.match(/android/i) != null && !device.mobile));
        if (device.tablet) {device.mobile = false;}
        device.desktop = !device.tablet && !device.mobile;
        device.touch = (typeof window.ontouchstart == "function" || navigator.MaxTouchPoints > 0 || navigator.msMaxTouchPoints > 0 || device.mobile || device.ipad || device.iphone || device.tablet || platform.android || platform.windowsphone) ? true : false;
    };

    var checkNavigator = function(){
        browser.ioswebview = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent);
        browser.firefox = ua.indexOf('firefox') > -1;
        browser.samsung = ua.indexOf('samsungbrowser') > -1;
        browser.ie = (ua.indexOf('msie') > -1 || window.ActiveXObject || "ActiveXObject" in window) && !browser.firefox;
        browser.chrome = ua.indexOf("chrome") > -1 && !browser.samsung;
        browser.safari = (ua.indexOf("safari") > -1 && !browser.chrome && !browser.samsung) || ua.match(/fbios/i) || ua.match(/micromessenger/i) ? true : false;
        browser.opera = ua.indexOf("presto") > -1;
        browser.facebook = (ua.indexOf("fban") > -1) || (ua.indexOf("fbav") > -1);
    };

    var checkPlatform = function(){
        platform.ios = (device.ipad || device.iphone) ? true : false;
        platform.win = /win/i.test(navigator.platform.toLowerCase());
        platform.mac = navigator.platform.toLowerCase().indexOf('mac')>=0;
        platform.android = ua.indexOf("android") > -1;
        platform.linux = ua.indexOf("linux") > -1;
        platform.windowsphone = ua.indexOf("windows phone") > -1 || (ua.match(/mobile/i) != null && browser.ie);
    };

    var checkVersion = function(){
        version.full = getBrowserVersion();
        version.main = parseInt(version.full);
        
        version.ie6 = cache.html.hasClass("ie6");
        version.ie7 = cache.html.hasClass("ie7");
        version.ie8 = cache.html.hasClass("ie8");
        version.ie9 = cache.html.hasClass("ie9");
        version.ie10 = navigator.appVersion.indexOf("MSIE 10") !== -1;
        version.ie11 = ua.match(/Trident\/7\./i) != null;
        version.edge = /Edge\/12./i.test(navigator.userAgent);
        
        version.ios7 = ua.match(/Version\/7/i) != null;
        version.ios9 = ua.match(/Version\/9/i) != null;
    };

    var checkEvents = function(){
        if (device.touch) {
            event.click = "touchstart";
            event.down = "touchstart";
            event.up = "touchend";
            event.move = "touchmove";

            if (window.navigator.msPointerEnabled) {
                event.click = "MSPointerDown";
                event.down = "MSPointerDown";
                event.up = "MSPointerUp";
                event.move = "MSPointerMove";
            }
        }
        else {
            event.click = "click";
            event.down = "mousedown";
            event.up = "mouseup";
            event.move = "mousemove";
        }
    };

    var checkCss = function(){
        css.transform3d = checkCssProperty('transform', 'translate3d(1px,1px,1px)', true);
    };

    var checkCssProperty = function(property, value, vendorPrefix){        
        var div = document.createElement('div');
        cache.html.append(div);
        
        var cssName = (vendorPrefix?browser.cssVendorPrefix:'') + property;
        var jsName = (vendorPrefix?browser.jsVendorPrefix:'') + uppercaseFirstLetter(property);
        var support = undefined;
        if (div.style[jsName] !== undefined) {
            div.style[jsName] = value;
            support = window.getComputedStyle(div).getPropertyValue(cssName);
        }
        $(div).remove();
        return (support !== undefined && support.length > 0 && support !== "none");
    };

    var addClasses = function () {
        // if (browser.firefox) {cache.html.addClass("firefox");}
        // if (browser.safari) {cache.html.addClass("safari");}
        
        // if (device.ipad) {cache.html.addClass("ipad");}
        // if (device.mobile) {cache.html.addClass("mobile");}
        
        if (device.touch) {cache.html.addClass("touch");}
        else {cache.html.addClass("notouch");}
    };

    var getBrowserVersion = function (){
        var N = navigator.appName, ua=navigator.userAgent, tem;
        var M = ua.match(/(opera|chrome|safari|firefox|msie)\/?\s*(\.?\d+(\.\d+)*)/i);
        if (M && (tem= ua.match(/version\/([\.\d]+)/i))!= null) M[2]= tem[1];
        M = M? [M[1], M[2]]: [N, navigator.appVersion, '-?'];
        return M[1];
    };

    var checkVendorPrefix = function(){
        var prefixes = {
            ioswebview:["-webkit-", "webkit"],
            facebook:["-webkit-", "webkit"],
            samsung:["-webkit-", "webkit"],
            chrome:["-webkit-", "webkit"],
            safari:["-webkit-", "webkit"],
            firefox:["-moz-", "Moz"],
            opera:["-o-", "O"],
            ie:["-ms-", "ms"]
        };
        
        for (var name in browser){
            if (browser[name]){
                browser.cssVendorPrefix = prefixes[name][0];
                browser.jsVendorPrefix = prefixes[name][1];
                return;
            }
        }
        browser.cssVendorPrefix = "";
        browser.jsVendorPrefix = "";
    };

    var checkVideoSupport = function () {
        var supports = {
            ioswebview:["mp4", "video/mp4"],
            facebook:["mp4", "video/mp4"],
            samsung:["mp4", "video/mp4"],
            chrome: ["mp4", "video/mp4"],
            safari: ["mp4", "video/mp4"],
            firefox: ["webm", "video/webm"],
            opera: ["webm", "video/webm"],
            ie: ["mp4", "video/mp4"]
        };
        
        for (var name in browser){
            if (browser[name]){
                browser.videoExtension = supports[name][0];
                browser.videoType = supports[name][1];
                return;
            }
        }
        browser.videoExtension = "mp4";
        browser.videoType = "video/mp4";
    };

    var checkWebGL = function(){
        var canvas;
        var ctx;
        var exts;

        try{
          canvas = document.createElement('canvas');
          ctx = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
          exts = ctx.getSupportedExtensions();
        }
        catch(e){
          //   if(window.WebGLRenderingContext)
          //       self.webgl = new Boolean(true);
          // return;
        }

        if(ctx !== undefined)
            self.webgl = new Boolean(true);

        if(ctx){
            for(var i = -1, len = exts.length; ++i < len;)
                self.webgl[exts[i]] = true;
        }

        canvas = undefined;
    }

    var uppercaseFirstLetter = function(str){
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    var addConsole = function () {
        if (!window.console) {
            var console = {
                log : function(){},
                warn : function(){},
                error : function(){},
                time : function(){},
                timeEnd : function(){},
                table : function(){}
            };
        }
    };

    init();
};

//GetComputedStyle polyfill
if (!window.getComputedStyle) {
    window.getComputedStyle = function(el, pseudo) {
        this.el = el;
        this.getPropertyValue = function(prop) {
            var re = /(\-([a-z]){1})/g;
            if (prop == 'float') prop = 'styleFloat';
            if (re.test(prop)) {
                prop = prop.replace(re, function () {
                    return arguments[2].toUpperCase();
                });
            }
            return el.currentStyle[prop] ? el.currentStyle[prop] : null;
        };
        return this;
    };
}

window["env"] = env;
module.exports = env;
