var TweenMax = require('gsap');
var env = require('./env.js');
var $   = require('jquery');

var tools = (function() {

    var _urlRegexp = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;

    var _removeDuplicateImages = function(images){
        var filteredImages = $();
        var urls = [];
        var identicals = {};
        $.each(images, function(){
            var image = $(this);
            var url = image.attr('src');
            if(urls.indexOf(url) == -1 && url != config.APP_URL){
                filteredImages = filteredImages.add(image);
                urls.push(url);
            }
            else{
                if(!identicals[url]){
                    identicals[url]= [];
                }
                identicals[url].push(image);
                
            }
        });
        return filteredImages;
    };

    var _setImagesSize = function(images) {
        images.each(function(){
            var item = $(this);
            item.data({
                width: item[0].naturalWidth || item.width(),
                height: item[0].naturalHeight || item.height()
            });
        });
    };

    var _fit = function(elements, options) {
        elements = $(elements);
        options = $.extend({
            inside:typeof options == "boolean"?options:false,
            animate:false,
            duration:1,
            ease:Quint.easeInOut,
            reference:null,
            ratio:null,
            shiftX:0,
            shiftY:0,
            minWidth:0,
            minHeight:0,
            maxWidth:null,
            maxHeight:null,
            useTransform:false,
            sizeOnly:false,
            heightOnly:false,
            widthOnly:false
        }, options);

        var t = new TimelineMax({paused:true});

        elements.each(function() {
            var item = $(this),
                reference = options.reference?options.reference:item.parent();
            var data = item.data();

            var newWidth, newHeight,
                x               = 0,
                y               = 0,
                width           = reference.outerWidth(),
                height          = reference.outerHeight(),
                containerRatio  = width/height,
                ratio           = options.ratio;

            if(!ratio){
                if(data.ratio)
                    ratio = data.ratio;
                else if(data.width && data.height)
                    ratio = data.width/data.height;
            }

            if(ratio){ 
                var condition = options.inside ? (containerRatio >= ratio) : (containerRatio < ratio);
                // Scale the image
                if (condition) {

                    newHeight = Math.max(height, options.minHeight);
                    newWidth  = newHeight * ratio;

                    if(newWidth < options.minWidth){
                        newWidth  = options.minWidth;
                        newHeight = newWidth / ratio;  
                    }
                    if(options.maxWidth != null && newWidth > options.maxWidth){
                        newWidth  = options.maxWidth;
                        newHeight = newWidth / ratio;  
                    }
                }
                else{

                    newWidth  = Math.max(width, options.minWidth);
                    newHeight = newWidth / ratio | 0; 
                    
                    if(newHeight < options.minHeight){
                        newHeight = options.minHeight;  
                        newWidth  = newHeight * ratio;
                    }
                    if(options.maxHeight != null && newHeight > options.maxHeight){
                        newHeight = options.maxHeight;  
                        newWidth  = newHeight * ratio;
                    }
                }
            }
            else{
                if(options.minWidth) newWidth = Math.max(width, options.minWidth);
                if(options.minHeight) newHeight = Math.max(height, options.minHeight);
                if(options.maxWidth != null) newWidth = Math.min(width, options.maxWidth);
                if(options.maxHeight != null) newHeight = Math.min(height, options.maxHeight);
            }
            x = (width - newWidth)/2 + options.shiftX;
            y = (height - newHeight)/2 + options.shiftY;


            var data = {};

            if(options.useTransform){
                data["force3D"] = true;
                if(newWidth && !options.heightOnly){
                    if(!options.sizeOnly && !options.widthOnly)
                        data["x"] = x;
                    data["width"] = newWidth;
                }
                if(newHeight && !options.widthOnly){
                    if(!options.sizeOnly && !options.heightOnly)
                        data["y"] = y;
                    data["height"] = newHeight;
                }
                if(options.animate){
                    data["ease"] = options.ease;
                    data["force3D"] = true;
                    t.to(item, options.duration, data, 0);
                }
                else TweenMax.set(item, data, 0);
            }
            else{
                if(newWidth && !options.heightOnly){
                    if(!options.sizeOnly && !options.widthOnly)
                        data["left"] = x;
                    data["width"] = newWidth;
                }
                if(newHeight && !options.widthOnly){
                    if(!options.sizeOnly && !options.heightOnly)
                        data["top"] = y;
                    data["height"] = newHeight;
                }
                if(options.animate){
                    data["ease"] = options.ease;
                    t.to(item, options.duration, data, 0);
                }
                else TweenMax.set(item, data, 0);
            }
        });
        
        return t.play();
    };

    var _center = function(elements, options){
        options = $.extend({
            animate:false,
            duration:1,
            ease:Quint.easeInOut,
            useTransform:false,
            reference:null,
            shiftX:0,
            shiftY:0
        }, options);

        var t = new TimelineMax({paused:true});

        elements.each(function() {
            var item = $(this),
                reference = options.reference?options.reference:item.parent(),
                left = (reference.outerWidth() - item.width())/2 + options.shiftX,
                top = (reference.outerHeight() - item.height())/2 + options.shiftY;  

            if(options.useTransform){
                if(options.animate){
                    t.to(item, options.duration, {x:left, y:top, force3D:true, ease:options.ease}, 0);
                }
                else TweenLite.set(item, {y:top, x:left, force3D:true});
            }
            else{
                if(options.animate){
                    t.set(item, {position:'absolute'}, 0);
                    t.to(item, options.duration, {position:'absolute', top:top, left:left, ease:options.ease}, 0);
                }
                else TweenLite.set(item, {position:'absolute', top:top, left:left});
            }
        });
        return t.play();
    };

    var _inArray = function(tableau, p_val) {
        var l = tableau.length;
        for(var i = 0; i < l; i++) {
            if(tableau[i] == p_val) {
                return true;
            }
        }
        return false;

    }

    var _retrieveImages = function(container) {

        var imgs = new Array();

        //add own back image!
        var url = "";

        // add all the imgs inside a div
        container.find("img").each(function() {
            url = $(this).attr("src");
            if(!_inArray(imgs, url) && typeof url != "undefined") imgs.push(url);
        });


        var everything = container.find("div").each(function() {
            var url = "";
            if ($(this).css("background-image") != "none") {
            
                url = $(this).css("background-image");
                url = url.replace("url(\"", "");
                url = url.replace("url(", "");
                url = url.replace("\")", "");
                url = url.replace(")", "");
            
                url = url.replace($("body").data("path"), "");
                if(url.length > 0
                && url.indexOf('linear-gradient') == -1
                && url.indexOf('-webkit-gradient') == -1) {
                    if(!_inArray(imgs, url)) imgs.push(url);
                }
            
            }
        
        });

        return imgs;

    }

    var _setResponsiveData = function(type, items){
        items.each(function(){
            var item = $(this),
                src    = item.data(_camelize(type+'-src')),
                width  = item.data(_camelize(type+'-width')),
                height = item.data(_camelize(type+'-height'));
            if(src)     item.attr('src', src);
            if(width) {
                item.data('width', width);
            }
            if(height){
                item.data('height', height);
            }
        });
    };

    var _setDataSize = function(items){
        $(items).each(function(){
            var item = $(this);
            var data = item.data();
            var obj = {};
            if(data.width) obj.width = data.width;
            if(data.height) obj.height = data.height;
            item.css(obj);
        });
    };
        

    var _modulo = function(n, m) {
        return ((n % m) + m) % m;
    };

    var _isNumber = function(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    };

    var _isArray = function(obj) {
        return Object.prototype.toString.call(obj) === '[object Array]';
    };

    var _isString = function(obj) {
        return typeof obj == 'string' || obj instanceof String;
    };

    var _isBoolean = function(obj) {
        return typeof obj == 'boolean';
    };

    var _isJQuery = function(obj) {
        return obj instanceof $ && obj.length > 0;
    };

    var _isRenderer = function(obj) {
        return typeof obj.render == "function";
    };
    
    var _urlParam = function(name){
        var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
        return results?(results[1] || 0):null;
    };
    
    var _stripTags = function(input, allowed) {
        allowed = (((allowed || '') + '').toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join('');
        // making sure the allowed arg is a string containing only tags in
        // lowercase (<a><b><c>)
        var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi, commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;
        return input.replace(commentsAndPhpTags, '').replace(tags, function($0, $1) {
            return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
        });

    };

    var _parseUrl = function(url) {
        var a = document.createElement('a');
            a.href = url;
        return a;
    };
    
    var _basename = function(url){
        return $.trim(url.substring(url.lastIndexOf('/')+1));
    };
    
    var _basepath = function(url){
        return $.trim(url.substring(0, url.lastIndexOf('/')+1));
    };
    
    var _ucfirst = function(string){
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    var _camelize = function(string){
        return string.replace(/^([A-Z])|[\s-_](\w)/g, function(match, p1, p2, offset) {
            if (p2) return p2.toUpperCase();
            return p1.toLowerCase();        
        });
    };
    
    var _stringToBoolean = function(string){
        if(typeof string == "boolean")
            return string;
        switch(string.toLowerCase()) {
            case "false": case "no": case "0": case "": return false;
            default: return true;
        }
    };

    var _parseMysqlDate = function(string) {  
        var t = string.split(/[- :]/);
        return new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]));
    };

    var _simulateTouchClick = function(items){
        var start,
            startX,
            startY,
            items = $(items);

        var endHandler = function(e){
            var end = (new Date).getTime(),
                first = event.changedTouches[0],
                endX = first.pageX,
                endY = first.pageY;

            if(end-start < 400 && Math.abs(endX-startX) < 10 && Math.abs(endY - startY) < 10){
                var simulatedEvent = document.createEvent('MouseEvent');
                    simulatedEvent.initMouseEvent('click', true, true, window, 1, first.screenX, first.screenY, first.clientX, first.clientY, false, false, false, false, 0/*left*/, null);
                    first.target.dispatchEvent(simulatedEvent);
                if(document.activeElement){
                    document.activeElement.blur();
                }
                switch(first.target.tagName.toLowerCase()){
                    case "input":
                    case "textarea":
                    case "select":
                        first.target.focus();
                        break;
                }
            }
        };

        var startHandler = function(e){
            e.preventDefault();
            start = (new Date).getTime();
            startX = event.changedTouches[0].pageX;
            startY = event.changedTouches[0].pageY;
            items.off('touchend', endHandler)
                 .on('touchend', endHandler);

        };

        items.off('touchstart', startHandler)
             .on('touchstart', startHandler);
    };

    var _forceRedraw = function(element){

        if (!element) { return; }

        var n = document.createTextNode(' ');
        var disp = element.style.display;  // don't worry about previous display style

        element.appendChild( n );
        element.style.display = 'none';

        setTimeout(function(){
            element.style.display = disp;
            n.parentNode.removeChild( n );
        }, 20); // you can play with this timeout to make it as short as possible
    };

    var _getExtension = function(url){
        var a = url.split(".");
        if( a.length === 1 || ( a[0] === "" && a.length === 2 ) ) {
            return "";
        }
        return a.pop();
    };


    var _trim = function(str, chars) {
        return _ltrim(_rtrim(str, chars), chars);
    };

    var _ltrim = function(str, chars) {
        chars = chars || "\\s";
        return str.replace(new RegExp("^[" + chars + "]+", "g"), "");
    };

    var _rtrim = function(str, chars) {
        chars = chars || "\\s";
        return str.replace(new RegExp("[" + chars + "]+$", "g"), "");
    };

    var _createStyleSheet = function() {
        // Create the <style> tag
        var style = document.createElement("style");

        // Add a media (and/or media query) here if you'd like!
        // style.setAttribute("media", "screen")
        // style.setAttribute("media", "only screen and (max-width : 1024px)")

        // WebKit hack :(
        if(env.browser.safari || env.browser.chrome){
            style.appendChild(document.createTextNode(""));
        }

        // Add the <style> element to the page
        document.getElementsByTagName('head')[0].appendChild(style);

        return style.sheet;
    };

    var _shuffleArray = function(a) {
        var j, x, i;
        for (i = a.length; i; i -= 1) {
            j = Math.floor(Math.random() * i);
            x = a[i - 1];
            a[i - 1] = a[j];
            a[j] = x;
        }
    };

    var _smoothstep = function(min, max, value) {
      var x = Math.max(0, Math.min(1, (value-min)/(max-min)));
      return x*x*(3 - 2*x);
    };

    var binarySearch = function(searchElement) {
        'use strict';
     
        var minIndex = 0;
        var maxIndex = this.length - 1;
        var currentIndex;
        var currentElement;
     
        while (minIndex <= maxIndex) {
            currentIndex = (minIndex + maxIndex) / 2 | 0;
            currentElement = this[currentIndex];
     
            if (currentElement < searchElement) {
                minIndex = currentIndex + 1;
            }
            else if (currentElement > searchElement) {
                maxIndex = currentIndex - 1;
            }
            else {
                return currentIndex;
            }
        }
     
        return -1;
    };

    var closest = function(num, arr) {
        var mid;
        var lo = 0;
        var hi = arr.length - 1;
        while (hi - lo > 1) {
            mid = Math.floor ((lo + hi) / 2);
            if (arr[mid] < num) {
                lo = mid;
            } else {
                hi = mid;
            }
        }
        if (num - arr[lo] <= arr[hi] - num) {
            return arr[lo];
        }
        return arr[hi];
    };

    var _longPress = function(container, callback, options){

        options = $.extend({
            longPressRadius:20,
            longPressDuration:600
        }, options);

        var checkLongTouchstart = function(e){
            var start  = $.now(),
                startX = e.originalEvent.touches[0].pageX,
                startY = e.originalEvent.touches[0].pageY,
                deltaX = 0,
                deltaY = 0,
                target = $(e.currentTarget),
                timer;

            var unregister = function(){
                clearTimeout(timer);
                target.off('touchend', unregister);
                target.off('touchmove', touchmove);
            };
            var touchmove = function(e2){
                deltaX = e2.originalEvent.touches[0].pageX - startX;
                deltaY = e2.originalEvent.touches[0].pageY - startY;
            };
            var timeout = function(){
                unregister();
                if(deltaX < options.longPressRadius
                && deltaY < options.longPressRadius){
                    callback();
                }
            };

            target.on('touchend', unregister);
            target.on('touchmove', touchmove);
            timer = setTimeout(timeout, options.longPressDuration);
        };

        var checkLongMousedown = function(e){
            var start  = $.now(),
                startX = e.pageX,
                startY = e.pageY,
                deltaX = 0,
                deltaY = 0,
                target = $(e.currentTarget),
                timer;

            var unregister = function(){
                clearTimeout(timer);
                target.off('mouseup', unregister);
                target.off('mousemove', mousemove);
            };
            var mousemove = function(e2){
                deltaX = e2.pageX - startX;
                deltaY = e2.pageY - startY;
            };
            var timeout = function(){
                unregister();
                if(deltaX < options.longPressRadius
                && deltaY < options.longPressRadius){
                    callback();
                }
            };

            target.on('mouseup', unregister);
            target.on('mousemove', mousemove);
            timer = setTimeout(timeout, options.longPressDuration);
        };


        if(env.device.touch){
            container.on('touchstart', checkLongTouchstart);
        }
        else {
            container.on('mousedown', checkLongMousedown);
        }
    };


    var _public = {
        setImagesSize: _setImagesSize,
        modulo: _modulo,
        isNumber: _isNumber,
        isArray: _isArray,
        isString: _isString,
        isBoolean:_isBoolean,
        isJQuery: _isJQuery,
        isRenderer: _isRenderer,
        urlParam:_urlParam,
        stripTags:_stripTags,
        parseUrl: _parseUrl,
        parseMysqlDate:_parseMysqlDate,
        basename:_basename,
        basepath:_basepath,
        ucfirst:_ucfirst,
        camelize:_camelize,
        stringToBoolean:_stringToBoolean,
        simulateTouchClick:_simulateTouchClick,
        forceRedraw:_forceRedraw,
        fit:_fit,
        center:_center,
        getExtension:_getExtension,
        trim:_trim,
        ltrim:_ltrim,
        rtrim:_rtrim,
        createStyleSheet:_createStyleSheet,
        shuffleArray:_shuffleArray,
        setResponsiveData:_setResponsiveData,
        setDataSize:_setDataSize,
        removeDuplicateImages:_removeDuplicateImages,
        retrieveImages:_retrieveImages,
        inArray:_inArray,
        smoothstep:_smoothstep,
        longPress:_longPress
    };

    return _public;

})();

module.exports = tools;
