var $ 			= require('jquery');
var crossroads 	= require('crossroads');

var router = new function() {

    var self   = this,
        cache  = {
            useHash:false
        };

    var currentRoute = {};

    var init = this.init = function() {
        initCache();
        registerEvents();


    };

    var initCache = function () {
        self.baseHref = $('base').attr('href') || tools.basepath(document.location.href);
        crossroads.normalizeFn = crossroads.NORM_AS_OBJECT;
        crossroads.ignoreState = true;
    };

    var registerEvents = function () {
        if(cache.useHash){
            //setup hasher
            function parseHash(newHash, oldHash){
                crossroads.parse(newHash);
            }
            hasher.initialized.add(parseHash);
            hasher.changed.add(parseHash);
            hasher.prependHash = '';
            hasher.init();
        }
        else{
            $('body').on('click', "a:not(.no-history)", onLinkClick);
            window.addEventListener('popstate', onStateChange);
            change(window.location.href, true);
        }
    };

    var add = this.add = function(pattern, fn, scope) {
        var route = crossroads.addRoute(pattern);
            route.matched.add(fn, scope);
        return route;
    };

    var onStateChange = function(e) {
        var state = history.state;
        if(state != self.currentState) {
			self.currentState = state;
            crossroads.parse(state.url.replace(self.baseHref, '').trim());
        }
    };

    var onLinkClick = function (e) {
        var href = $(this).attr("href");
        if (typeof href == "undefined") {return;}
        if (href == self.baseHref
            || href.indexOf('tel:') > -1
            || href.indexOf('mailto:') > -1
            || (href.indexOf(self.baseHref) == -1 && (href.indexOf("http://") > -1 || href.indexOf("https://") > -1))
            || $(this).attr("target") == "_blank") {return;}

        e.preventDefault();
        e.stopPropagation();
        change(href);
    };

    var change = this.change = function(href, replace) {
        if(cache.useHash){
            changeHash(href, replace);
        }
        else{
            changeHistory(href, replace);
        }
    };

    var changeHash = function(href, silent){
        if(silent) hasher.changed.active = false;
        hasher.setHash(href);
        if(silent) hasher.changed.active = true;
    };

    var changeHistory = function(href, replace){
        var href = href.replace(self.baseHref, '');
        if(href == '' || href == "./") href = self.baseHref;
        var data = {
            url:href,
            title:$('title').text()
        };
        if(!self.currentState || self.currentState.url != data.url) {
            if(replace) history.replaceState(data, data.title, data.url);
            else history.pushState(data, data.title, data.url);
            onStateChange();
        }
    };

    var catchAll = self.catchAll = function(callback){
        crossroads.bypassed.add(callback);
    }
};

module.exports = router;
