var $ = require('jquery');
var Player = require('@vimeo/player');

var vimeo = function($container, _options){
	_options = $.extend({
        id:'',
        toolbar:$container.find('.toolbar'),
        autoPlay: false
    }, $container.data(), _options);

    var playerId = 'ytplayer_'+_options.id;
    var $overlay = $container.find('.overlay');
	var $player = $('<div id="'+playerId+'"></div>').appendTo($container);
    
	var player = new Player($player[0], {
	    id: _options.id,
	    width: '100%',
	    title:false
	});

    var _registerEvents = function(){
		player.on('play', changeState('play'));
		player.on('pause', changeState('pause'));
		player.on('ended', changeState('ended'));
		player.on('loaded', function(){
			$player.find('iframe').attr("scrolling", "no");
		});
        $overlay.on('click', _toggle);

        if(_options.toolbar.length){
            _options.toolbar.find('.play').on('click', _toggle);
            _options.toolbar.find('.mute').on('click', _mute);
            _options.toolbar.find('.tofullscreen').on('click', _fullscreen);
            _options.toolbar.find('.progress').on('click', _progressClicked);
            _options.toolbar.find('.youtube').on('click', function(){
                window.open('https://www.youtube.com/watch?v='+_options.id, '_blank');
            });
        }
    };

    var _unRegisterEvents = function(){
		player.off('play');
		player.off('pause');
		player.off('ended');
        player.off('loaded');
        $overlay.off('click', _toggle);
        if(_options.toolbar.length){
            _options.toolbar.find('.play').off('click', _toggle);
            _options.toolbar.find('.mute').off('click', _mute);
            _options.toolbar.find('.tofullscreen').off('click', _fullscreen);
            _options.toolbar.find('.progress').off('click', _progressClicked);
            _options.toolbar.find('.youtube').off('click');
        }
    };

    var _progressClicked = function(e){
        var offset = (e.offsetX || e.pageX - $(e.target).offset().left);
        var progress = offset/_options.toolbar.find('.progress').outerWidth();
        _seek(_ytPlayer.getDuration()*progress);
    };

    var changeState = function(state){
    	var state2 = state;
    	return function(){
    		return stateChanged(state2);
    	}
    }

    var stateChanged = function(state){
        switch(state){
            case "pause":
            case "ended":
                _toggleUpdate(false);
                break;
            case "play":
                _toggleUpdate(true);
                break;
        }
        _updateToolbar();
    }

    var _updateTimer;
    var _updating;
    var _toggleUpdate = function(toggle){
        if(toggle === true && !_updating){
            _updating = true;
            _updateTimer = setInterval(_updateToolbar, 500);
        }
        else if(toggle === false && _updating){
            clearInterval(_updateTimer);
            _updating = false;
        }
    };

    var _updateToolbar = function(){
        if(!_options.toolbar.length)
            return;

        var time  = player.getCurrentTime();
        var total = player.getDuration();
        var t     = _options.toolbar;

        var playing = !player.getPaused(),
            progress = (time/total)*100 | 0;
        t.find('.play').toggleClass('selected', playing);
        t.find('.mute').toggleClass('selected', player.getVolume() == 0);
        TweenLite.to(t.find('.progress span'), 0.5, {width:progress+'%'});
    };
	
	var _play = function(){
        if(!player) return;
        player.play();
    };

    var _pause = function(){
        if(!player) return;
        player.pause();
    };

    var _toggle = function(){
        if(!player) return;
        player.getPaused()?_play():_pause();
    };

    var previousVolume = 1;
    var _mute = function(){
        if(!player) return;
        player.setVolume(player.getVolume()==0?1:0);
    };

    var _fullscreen = function(){
        if(!player) return;
        var iframe = $("#"+_playerId)[0];
        var requestFullScreen = iframe.requestFullScreen || iframe.mozRequestFullScreen || iframe.webkitRequestFullScreen;
        if (requestFullScreen) {
            requestFullScreen.bind(iframe)();
        }
    };

    var _seek = function(value){
        if(!_ytPlayer) return;
        player.setCurrentTime(value, true);
    };

    var _destroy = function(){
        if(_options.toolbar.length) {
            var t = _options.toolbar;
            t.find('.play').removeClass('selected');
            t.find('.mute').removeClass('selected');
            TweenLite.set(t.find('.progress span'), {width:'0%'});  
        }

        _unRegisterEvents();
        clearInterval(_updateTimer);
        try {player.unload();} catch(e) {}
    };

    var _public = {
        play:_play,
        pause:_pause,
        toggle:_toggle,
        mute:_mute,
        seek:_seek,
        destroy:_destroy
    };


    _registerEvents();

    return _public;
};

module.exports = vimeo;