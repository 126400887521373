var $ 	 	 = require('jquery');
var gsap 	 = require('gsap');
var viewport = require('../tools/viewport');
var tools    = require('../tools/tools');
var router   = require('../tools/router');
var profile  = require('../profile');
var debounce = require('throttle-debounce').debounce;

var header = function($header){
	var $menu = $header.find('.menu');
	var $bar  = $header.find('.bar');
    var $logo = $header.find('.logo');

	var $open  = $bar.find('.button.open');
	var $close = $bar.find('.button.close');

    var $search = $bar.find('.search');
	var $project = $bar.find('.projects');

	var $links  = $header.find('a');

    var $form = $menu.find('form');
    var $input = $form.find('input[type="search"]');
    var $placeholder = $form.find('.placeholder');

    var $white = $form.find('img.white')
    var $black = $form.find('img.black')

    var lastProfile = null;

    tools.simulateTouchClick($header);

	var resetScroll = function(){
		if(window.oldScroll !== undefined) window.oldScroll = 0;
	};

	var resize = function(){
		$menu.height(viewport.height);
	};

	var openMenu = function(){
		$header.addClass('menu-opened');
        lastProfile = profile.current;
        profile.set('white-on-black');
	};
	var closeMenu =function(){
		$header.removeClass('menu-opened');
        if(!lastProfile)
            lastProfile = profile.current;
        profile.set(lastProfile);
	};

    var openSearch = function(e){
        e.preventDefault();
        e.stopPropagation();
        var $comp = $('#search');
        if($comp.length){
            var comp = $comp.data('comp');
            if(comp){
                comp.open();
            }
        }
    };

    var keypressed = function(e){
        var text = $input.val();

        if(text.length == 0){
            $placeholder.show();
        }
        else{
            $placeholder.hide();
        }
    };

    var submit = debounce(4000, true, function(e){
        e.preventDefault();
        e.stopPropagation();
        $input.blur();
        router.change('projects/q?text='+encodeURIComponent($input.val()));
        closeMenu();

        return false;
    });


    var open = function(){
        profile.set($search.data('profile'))
        var t = new TimelineMax({paused:true});

        return t.play();
    };
    var close = function(){
        var t = new TimelineMax({paused:true});

        return t.play();
    };

    var attach = function(){
        detach();

        viewport.on('resize', resize);


        $form.on('submit', submit);
        $input.on('keyup keydown', keypressed);
        $search.on('click', openSearch);
        $open.on('click', openMenu);
        $close.on('click', closeMenu);
        $links.on('click', closeMenu);
		$project.on('click', resetScroll);
    };
    var detach = function(){
        $form.off('submit', submit);
        $input.off('keyup keydown', keypressed);
        $search.off('click', openSearch);
        viewport.off('resize', resize);
        $open.off('click', openMenu);
        $close.off('click', closeMenu);
        $links.off('click', closeMenu);
		$project.off('click', resetScroll);
    };

    attach();

    var _public = {
        open:open,
        close:close,
        attach:attach,
        detach:detach
    }

    return _public;
};

module.exports = header;
