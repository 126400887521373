var $ 	   	= require('jquery');
require('./libs/jquery.preload');
var gsap   	    = require('gsap');
var router 		= require('./tools/router');
var viewport    = require('./tools/viewport');
var tools       = require('./tools/tools');
var components  = require('./components');
var responsive  = require('./responsive');
var profile     = require('./profile');
var scroll      = require('./scroll');

if(history && history.scrollRestoration)
    history.scrollRestoration = "manual";

$(function(){

    var $body       = $('body');
    var $wTitle     = $('head title');
    var $mTitle     = $('head meta[property="og:title"]');
	var $root       = $('#root');
    var $overlay    = $('#overlay');
	var $previous   = $root.find('> *').eq(0);
    var first       = true;
    var prevRoute   = false;

    var preload = function(){
        var defer = $.Deferred();
        var urls = [];
        if(viewport.medias.mobile){
            $('.responsive-src[data-mobile-src]').each(function(){
                var url = $(this).data('mobileSrc');
                if(urls.indexOf(url) == -1) urls.push(url);
            });
            urls.push(config.ROOT_URL + 'assets/images/mobile/header-gradient.png');
        }
        else{
            $('.responsive-src[data-desktop-src]').each(function(){
                var url = $(this).data('desktopSrc');
                if(urls.indexOf(url) == -1) urls.push(url);
            });

            urls.push(config.ROOT_URL + 'assets/images/desktop/gradient-left.png');
            urls.push(config.ROOT_URL + 'assets/images/desktop/gradient-right.png');
        }

        $.preload(urls, {
            onComplete:function(data){
                defer.notify(data.done/data.total);
            },
            onFinish:function(){
                defer.resolve();
            }
        });

        return defer.promise();
    };

	var load = function(route){
		if(first){
            preload().done(function(){
                components.scan();
                responsive.setup();

                if($previous.data('comp')){
                    $previous.data('comp').attach();
                }

                transition($previous, null, true);
                prevRoute = route;
                first = false;
                setTimeout(function(){
                    viewport.resize();
                }, 200);
            });
			return;
		}

		$.post(route).done(function(html){
			var $next = $(html);

            $previous.removeClass('responsive');
            $next.css('position', 'absolute');
            $root.prepend($next);
            components.scan();
            responsive.setup();

            $next.show();

            var previousComp = $previous.data('comp');
            var nextComp     = $next.data('comp');
            if(previousComp){
                previousComp.detach();
            }
            if(nextComp){
                nextComp.attach();
            }

            var t = new TimelineMax({paused:true});
                t.add(transition($previous, $next));
                t.play();

			$previous = $next;

            if(prevRoute != 'projects' && route != 'projects') {
                window.oldScroll = false;
            }
            prevRoute = route;

		});
	};

	var transition = function($from, $to, isLanding){
        viewport.resize();
		$to = $to == null ? $from : $to;
		var t = new TimelineMax({paused:true});
		if($from && $from != $to){
            if($from.data('comp')) t.add($from.data('comp').close(isLanding));
            t.add(function(){
                $from.remove();
                viewport.resize();
            });
        }
		if($to){
            var $h1 = $to.find('h1');
            if($h1.length){
                t.add(function(){
                    $wTitle.html($h1.text() + " - " + $mTitle.attr("content"));
                })
            }
            else{
                $wTitle.html($mTitle.attr("content"));
            }
            // if($from){
            //     $to.before($from);
            // }

            t.call(profile.set, [$to.data('profile')], profile);
            t.call(function(){
                TweenMax.set($('html, body'), {scrollTop:0});
                scroll.scrollY = 0;
                scroll.smoothedScrollY = 0.1;
                $to.css('position', 'relative');
            });
            if($to.data('comp')) 	t.add($to.data('comp').open(isLanding));
        }

        if(first){
            t.to($("#logo"), 0.5, {opacity:1, ease:Linear.easeNone}, 0);

            t.to($(".main-loader").addClass('notransition'), 0.8, {opacity:0, ease:Quad.easeInOut, onComplete: function(){
                $(".main-loader").hide();
            }}, 0);
        }

        var searchComp = $('#search').data('comp');
        if(searchComp && searchComp.close) searchComp.close();

        if(isLanding){
            t.to($overlay, 1.5, {x:-viewport.width, force3D:true, ease:Quint.easeInOut}, 0);
            t.add(function(){
                $overlay.remove();
            }, 1.5);
        }
		return t.play();
	};

    router.catchAll(load);
	router.init();
});


module.exports = router;
