var Point = require('./point');

var Rectangle = (function(x, y, width, height){
    var _public = {
        x:0,
        y:0,
        width:0,
        height:0
    };

    var _contains = function(x, y){
        return Rectangle.contains(_public, x, y);
    };

    var _getCenter = function(){
        return Rectangle.getCenter(_public);
    };

    var _getBounds = function(){
        return Rectangle.getBounds(_public);
    };

    var _set = function(x, y, width, height){
        _public.x       = x === undefined?_public.x:x;
        _public.y       = y === undefined?_public.y:y;
        _public.width   = width === undefined?_public.width:width;
        _public.height  = height === undefined?_public.height:height;
        return _public;
    };

    _public.contains    = _contains;
    _public.set         = _set;

    _set(x, y, width, height);

    return _public;
});
Rectangle.TEMPORARY = Rectangle();
Rectangle.contains = function(rect, x, y){
    return      x > rect.x
            &&  x < rect.x + rect.width
            &&  y > rect.y
            &&  y < rect.y + rect.height;
};
Rectangle.getCenter = function(rect, x, y){
    return Point(
        _public.width/2,
        _public.height/2
    );
};
Rectangle.getBounds = function(rect){
    var p = rect.position
    return [
        p.x, p.y,
        p.x+rect.width, p.y,
        p.x+rect.width, p.y+rect.height,
        p.x, p.y+rect.height,
    ];
};

module.exports = Rectangle;