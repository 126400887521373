var $ = require('jquery');

var apiReady = false;
var _players = {};

var _insertAPI = function(){
	var tag = document.createElement('script');
		tag.src = "https://www.youtube.com/iframe_api";
	var firstScriptTag = document.getElementsByTagName('script')[0];
		firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
};

window["onYouTubeIframeAPIReady"] = function(){
	//console.log('YT Api Ready');
	$.each(_players, function(id, player){
		player._initPlayer();
	});
	apiReady = true;
};
_insertAPI();

var youtube = function($container, _options){
	
	_options = $.extend({
        id:'',
        toolbar:$container.find('.toolbar'),
        autoPlay: false
    }, $container.data(), _options);

	var $ytPlayer, $overlay,
        _playerId, _ytPlayer;

    var _init = function(){
        _playerId = 'ytplayer_'+_options.id;
        $overlay  = $container.find('.overlay');
        $ytPlayer = $('<div id="'+_playerId+'"></div>').appendTo($container);
        _players[_playerId] = _public;

        _registerEvents();
        
        if(apiReady)
            _initPlayer();
    };

    var _registerEvents = function(){
        $overlay.on('click', _toggle);
        if(_options.toolbar.length){
            _options.toolbar.find('.play').on('click', _toggle);
            _options.toolbar.find('.mute').on('click', _mute);
            _options.toolbar.find('.tofullscreen').on('click', _fullscreen);
            _options.toolbar.find('.progress').on('click', _progressClicked);
            _options.toolbar.find('.youtube').on('click', function(){
                window.open('https://www.youtube.com/watch?v='+_options.id, '_blank');
            });
        }
    };

    var _unRegisterEvents = function(){
        $overlay.off('click', _toggle);
        if(_options.toolbar.length){
            _options.toolbar.find('.play').off('click', _toggle);
            _options.toolbar.find('.mute').off('click', _mute);
            _options.toolbar.find('.tofullscreen').off('click', _fullscreen);
            _options.toolbar.find('.progress').off('click', _progressClicked);
            _options.toolbar.find('.youtube').off('click');
        }
    };

    var _initPlayer = function(){
        _ytPlayer = new YT.Player(_playerId, {
            height: '100%',
            width: '100%',
            videoId: _options.id,
            playerapiid: _playerId,
            playerVars:{
                playsinline:1,
                controls: (_options.toolbar.length) ? "0" : "1",
                fs:0,
                modestbranding:1,
                rel:0,
                showinfo:0,
                autohide:1,
                wmode:'opaque',
                version:1,
                autoPlay: 1
            },
            events: {
                'onReady': _onReady,
                'onStateChange': _onStateChange,
                'onError':_onError
            }
        });
        
    };

    var _progressClicked = function(e){
        var offset = (e.offsetX || e.pageX - $(e.target).offset().left);
        var progress = offset/_options.toolbar.find('.progress').outerWidth();
        _seek(_ytPlayer.getDuration()*progress);
    };

    var _onError = function(e){
        console.log('YT Error', e.data);
    };

    var _onReady = function(e){
        // console.log('READY', e.data);
        
//        if (_options.autoPlay) {_play();}
    };

    var _onStateChange = function(e){
        switch(e.data){
            case YT.PlayerState.ENDED:
                _toggleUpdate(false);
                break;
            case YT.PlayerState.PLAYING:
                _toggleUpdate(true);
                break;
            case YT.PlayerState.PAUSED:
                _toggleUpdate(false);
                break;
            case YT.PlayerState.BUFFERING:
                _toggleUpdate(false);
                break;
            case YT.PlayerState.CUED:
                _toggleUpdate(false);
                break;
        }
        _updateToolbar();
    };

    var _updateTimer;
    var _updating;
    var _toggleUpdate = function(toggle){
        if(toggle === true && !_updating){
            _updating = true;
            _updateTimer = setInterval(_updateToolbar, 500);
        }
        else if(toggle === false && _updating){
            clearInterval(_updateTimer);
            _updating = false;
        }
    };

    var _updateToolbar = function(){
        if(!_options.toolbar.length)
            return;

        var state = _ytPlayer.getPlayerState();
        var time  = _ytPlayer.getCurrentTime();
        var total = _ytPlayer.getDuration();
        var t = _options.toolbar;

        console.log(time, total);

        var playing = state == YT.PlayerState.PLAYING,
            progress = (time/total)*100 | 0;
        t.find('.elapsed span').html(formatDuration(time));
        t.find('.duration span').html(formatDuration(total));
        t.find('.play').toggleClass('selected', playing);
        t.find('.mute').toggleClass('selected', _ytPlayer.isMuted());
        TweenLite.to(t.find('.progress span'), 0.5, {width:progress+'%'});
    };

    var _play = function(){
        if(!_ytPlayer) return;
        _ytPlayer.playVideo();
    };

    var _pause = function(){
        if(!_ytPlayer) return;
        _ytPlayer.pauseVideo();
    };

    var _toggle = function(){
        if(!_ytPlayer) return;
        _ytPlayer.getPlayerState() == YT.PlayerState.PLAYING?_pause():_play();
    };

    var _mute = function(){
        if(!_ytPlayer) return;
        _ytPlayer.isMuted()?_ytPlayer.unMute():_ytPlayer.mute();
    };

    var _fullscreen = function(){
        if(!_ytPlayer) return;
        var iframe = $("#"+_playerId)[0];
        var requestFullScreen = iframe.requestFullScreen || iframe.mozRequestFullScreen || iframe.webkitRequestFullScreen;
        if (requestFullScreen) {
            requestFullScreen.bind(iframe)();
        }
    };

    var _seek = function(value){
        if(!_ytPlayer) return;
        _ytPlayer.seekTo(value, true);
    };

    var _destroy = function(){
        if(_options.toolbar.length) {
            var t = _options.toolbar;
            t.find('.play').removeClass('selected');
            t.find('.mute').removeClass('selected');
            TweenLite.set(t.find('.progress span'), {width:'0%'});  
        }

        _unRegisterEvents();
        clearInterval(_updateTimer);
        try {_ytPlayer.destroy();} catch(e) {}
    };

    var formatDuration = function(seconds){
        var numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60) | 0;
        var numseconds = (((seconds % 31536000) % 86400) % 3600) % 60 | 0;
        // numminutes = numminutes<10?"0"+numminutes:numminutes;
        numseconds = numseconds<10?"0"+numseconds:numseconds;
        return numminutes + ":" + numseconds;

    }


    var _public = {
        _initPlayer:_initPlayer,
        play:_play,
        pause:_pause,
        toggle:_toggle,
        mute:_mute,
        seek:_seek,
        fullscreen:_fullscreen,
        destroy:_destroy
    };

    _init();

    return _public;
};

module.exports = youtube;