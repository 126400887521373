var bindable = (function(item){
    
    item.__listeners = {};
    
    var _on = function(e, fn){
        item.__listeners[e] = item.__listeners[e]?item.__listeners[e]:[];
        if(typeof fn == "function"){
            item.__listeners[e].push(fn);

            _trigger('on', e, fn);
            return item;
        }

        return _trigger.apply(item, arguments);
    };
    var _off = function(e, fn){
        if(typeof fn == "function"){
            if(item.__listeners[e]){
                for(var i = 0; i< item.__listeners[e].length; i++){
                    if(item.__listeners[e][i] === fn){
                        item.__listeners[e][i] = null;
                        delete item.__listeners[e][i];
                        _trigger('off', e, fn);
                        return item;
                    }
                }
            }
        }
        else if(item.__listeners[e] && !fn){
            while(item.__listeners[e].pop()){};
            _trigger('off', e);
        }
        else if(arguments.length == 0){
            $.each(item.__listeners, function(key, listener){
                while(item.__listeners[key].pop()){}
                _trigger('off', key);
            });
        }
        return item;
    };

    var _oneTime = function(e, fn){
        var fn2 = function(){
            _off(e, fn2);
            fn.apply(item, arguments);
        };
        return _on(e, fn2);
    };

    var _trigger = function(e){
        if(item.__listeners[e]){
            var args = Array.prototype.slice.call(arguments);
                args.shift();
            for(var i = 0; i< item.__listeners[e].length; i++){
                if(item.__listeners[e][i]){
                    item.__listeners[e][i].apply(item, args);
                }
            }
        }
        return item;
    };


    
    item.on         = _on;
    item.off        = _off;
    item.oneTime    = _oneTime;
    item.once       = _oneTime;
    item.trigger    = _trigger;
    
    return item;
});

module.exports = bindable;