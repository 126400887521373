var gsap = require('gsap');
var $    = require("jquery");

var reveal = function(item, options){

    var duration = options && options.duration ? options.duration : 1;
    var alphaDuration = options && options.alphaDuration ? options.alphaDuration : (duration/2);
    var alphaPosition = options && options.alphaPosition ? options.alphaPosition : 0;

    options = $.extend({
        x: 0,
        y: 40,
        alpha: 0,
        delay:0, //decalage du demarrage de l'anim (en seconde)
        ease: Expo.easeOut,
        duration: duration,
        immediateRender:true,
        alphaDuration:alphaDuration,
        alphaPosition:alphaPosition
    }, options);
    var t = new TimelineMax({paused: true});
        t.fromTo(item, options.duration, {x:options.x, y: options.y}, {x:0, y: 0, immediateRender:options.immediateRender, clearProps:"transform", force3D: true, ease: options.ease}, options.delay);
        t.fromTo(item, options.alphaDuration, {alpha: options.alpha}, {alpha: 1, immediateRender:options.immediateRender, ease: Linear.easeNone}, options.delay+options.alphaPosition);
    return t;
};

var hide = function(item, options){

    var duration = options && options.duration ? options.duration : 0.5;
    var alphaDuration = options && options.alphaDuration ? options.alphaDuration : duration;
    var alphaPosition = options && options.alphaPosition ? options.alphaPosition : (duration - alphaDuration);

    options = $.extend(true, {
        x: 0,
        y: 40,
        alpha: 0,
        delay:0, //decalage du demarrage de l'anim (en seconde)
        ease: Expo.easeIn,
        duration: duration,
        immediateRender:true,
        alphaDuration:alphaDuration,
        alphaPosition:alphaPosition,
        from:{
            x:0,
            y:0,
            alpha:1
        }
    }, options);

    var t = new TimelineMax({paused: true, delay : options.delay});
    if(options.from){
        t.fromTo(item, options.duration, {x:options.from.x, y:options.from.y}, {x:options.x, y: options.y, immediateRender:options.immediateRender, force3D: true, ease: options.ease},0);
        t.fromTo(item, options.alphaDuration, {alpha:options.from.alpha}, {alpha: options.alpha, immediateRender:options.immediateRender, ease: Linear.easeNone}, options.alphaPosition);
    }
    else{
        t.to(item, options.duration, {x:options.x, y: options.y, immediateRender:options.immediateRender, force3D: true, ease: options.ease},0);
        t.to(item, options.alphaDuration, {alpha: options.alpha, immediateRender:options.immediateRender, ease: Linear.easeNone}, options.alphaPosition);
    }
    return t.play();
};
var fadeIn = function(item, options){
    options = $.extend({
        alpha: 0,
        delay:0, //decalage du demarrage de l'anim (en seconde)
        ease: Linear.easeNone,
        duration: 0.5,
        immediateRender:true
    }, options);

    var t = new TimelineMax({paused: true, delay : options.delay});
        t.fromTo(item, options.duration, {alpha: options.alpha}, {alpha: 1, immediateRender:options.immediateRender, ease: Linear.easeNone}, 0);
    return t.play();
};
var fadeOut = function(item, options){
    options = $.extend(true, {
        alpha:0,
        delay:0, //decalage du demarrage de l'anim (en seconde)
        ease: Linear.easeNone,
        duration: 0.5,
        immediateRender:true,
        from:{
            alpha:1
        }
    }, options);
    var t = new TimelineMax({paused: true, delay : options.delay});
    if(options.from){
        t.fromTo(item, options.duration, {alpha:options.from.alpha}, {alpha: options.alpha, immediateRender:options.immediateRender, ease: Linear.easeNone}, 0);
    }
    else{
        t.to(item, options.duration, {alpha: options.alpha, immediateRender:options.immediateRender, ease: Linear.easeNone}, 0);
    }
    return t.play();
};
module.exports = {
    reveal:reveal,
    hide:hide,
    fadeIn:fadeIn,
    fadeOut:fadeOut
};
