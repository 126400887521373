var tools    = require('./tools/tools');
var fit      = require('./tools/fit');
var viewport = require('./tools/viewport');
var $        = require('jquery');
var scroll   = require('./scroll');

//Setup medias
viewport.add({
    name:'desktop',
    minWidth:768
});
viewport.add({
    name:'mobile',
    maxWidth:768
});
viewport.add({
    name:'tablet',
    minWidth:768,
    maxWidth:1024
});
viewport.add({
    name:'desktop-small',
    minWidth:1024,
    maxWidth:1280
});

var currentMedia = null;

var setup = function(media){
    media = media == null ? currentMedia : media;
    if(media){
        tools.setResponsiveData(media.name == "mobile"?"mobile":"desktop", $('.responsive .responsive-src, .responsive .responsive-size'));
        tools.setDataSize($('.responsive .responsive-size'));
        currentMedia = media;
    }
};
var resize = function(){
    $('.responsive .fullscreen:not(.notreally)').css({
        width:viewport.width,
        height:viewport.height
    });

    $('.responsive .fullscreen.notreally').css({
        width:viewport.width,
        height:Math.round((viewport.height/100)*90)
    });
};
var postResize = function(){
    fit($('.responsive .fit'), {
        useTransform:false
    });
};

viewport.on('media', setup);
viewport.on('resize', resize);
viewport.on('post-resize', postResize);
viewport.resize();

var responsive = {
    setup:setup,
    resize:resize
};

module.exports = responsive;
