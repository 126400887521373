var $           = require('jquery');
var router      = require('../tools/router');
var responsive       = require('../responsive');
var tools       = require('../tools/tools');
var fit      = require('../tools/fit');
var viewport    = require('../tools/viewport');
var CustomEase  = require('../tools/customease.min.js');
var animations  = require('../animations');
var scroll    = require('../scroll');

$.fn.reverse = function() {
    return this.pushStack(this.get().reverse(), arguments);
};

var sheet = tools.createStyleSheet();

var colorRules = {};
var addColor = function(color){
    if(!colorRules.hasOwnProperty(color)){
        sheet.insertRule('[data-color="'+color+'"] { color:'+color+'; border-color:'+color+'; }', 0);
    }
};

var home = function($home){
    var $body       = $('body');

    var $title  = $home.find('.title-bloc h1');
    var $firstFeatured  = $home.find('.feature').first();

    var $cards       = $home.find('.card');
    var $scrollpane  = $home.find('.scrollpane');
    var $head        = $cards.eq(0);
    var $overlay     = $('#overlay');
    var $scroll      = $home.find('.scroll');
    var $selected    = $home.find('.selected');

    var $feature = $home.find('.feature');

    var $grid = $home.find('.grid');
    var $gridItemWrapper = $grid.find('.wrapper');
    var $gridItem = $gridItemWrapper.find('.grid-item');
    var $gridItemAnim = $gridItem.filter(':nth-child(3n+1), :nth-child(3n+3)');
    var $gridSpinner = $grid.find('.loader');

    var cardHeight = viewport.height;
    var first   = true;
    var closed  = true;
    var disableCloseAnim = false;
    var skipped = false;

    var grid = {total: $grid.data('total'), current: 12, step: 12, isLoading: false};

    $feature.each(function(){
        addColor($(this).data('color'));
    });

    var loadGridItems = function(){
        if(grid.isLoading || scroll.scrollDirection <= 0) return;
        grid.isLoading = true;

        var end = grid.current + grid.step;
        var $nextItems = $gridItem.slice(grid.current, end);
        var imgs = [];

        $nextItems.each(function() {
            var img = $(this).find('.background img');
            var src = img.data('desktop-src');

            if(viewport.medias.mobile) {
                src = img.data('mobile-src');
            }

            imgs.push(src);
        });

        if(imgs.length == 0) {
            $gridSpinner.remove();
            return;
        }

        $.preload(imgs, {
            onFinish:displayGridItems
        });
    };

    var displayGridItems = function(){
        var end = grid.current + grid.step;
        var $nextItems = $gridItem.slice(grid.current, end);

        var t = new TimelineMax({paused:true});
        var stagger = 0.1;
        var options = {
            y:0,
            duration:1.5
        };

        $nextItems.addClass('display');

        $nextItems.each(function(i) {
            var $item = $(this);
            var i3  = i/3 | 0;
            var pos = Math.abs(i%3-1)*stagger + stagger * i3;

            t.fromTo($item, options.duration/2, {alpha: 0}, {alpha: 1, ease: Linear.easeNone}, pos);
        });
        t.play();

        $nextItems.find('.background img').addClass('responsive-src responsive-size');
        responsive.setup();
        viewport.resize();

        grid.current += grid.step;
        grid.isLoading = false;
    };

    var resize = function(){
        $gridItem.find('.content').each(function(){
            $content = $(this);
            var maxWidth = $content.find('h4').width();
            $content.find('h4 span').removeAttr('style').each(function(){
                fitSize($(this), maxWidth);
            });
        });
    };

    var buildAnimations = function(e, scroller){
        var $reveals = $home.find('.grid h3, .feature h2');
        $reveals.each(function(){
            var item = $(this);
            scroll.reveal(item, {
                repeat:-1
            });
        });

        var $lastGridItem = $gridItemWrapper.find('.grid-item.display').last();
        if($lastGridItem.length) scroll.call($lastGridItem, loadGridItems, {offset: $lastGridItem.outerHeight()/2});

        if(!viewport.medias.mobile) {
            TweenLite.set($gridItemWrapper, {paddingBottom : 0});
            var y = Math.max(100, ($gridItemWrapper.outerHeight()/100)*5);
            scroll.timeline.fromTo($gridItemAnim, $gridItemWrapper.outerHeight(), {y: 0}, {y: y, force3D: true}, scroll.getPosition($gridItemWrapper)+50);
            TweenLite.set($gridItemWrapper, {paddingBottom : y+10});
        }
    };

    var fitSize = function(span, maxWidth, size){
        if(maxWidth < span.outerWidth()){
            size = parseFloat(span.css('font-size'))
            size--;
            span.css('font-size', size+'px');

            setTimeout(function(){
                fitSize(span, maxWidth, size);
            }, 10);
        }
    }

    var openProject = function(e){
        e.preventDefault();

        detach();

        var $project = $(this);

        router.change($project.find('a').attr('href'));
    };

    var preventDefault = function(e){
        e.preventDefault();
    };

    var open = function(isLanding){
        var t = new TimelineMax({paused:true});
        t.to($home, 0.5, {opacity:1, ease:Linear.easeNone}, 0);
        t.fromTo($title, 1, {y: 40}, {y: 0, force3D: true, ease: Expo.easeOut}, 0.6);
        t.fromTo($title, 0.5, {alpha: 0}, {alpha: 1, ease: Linear.easeNone},0.6);
        t.fromTo($firstFeatured, 0.5, {alpha: 0}, {alpha: 1, ease: Linear.easeNone},0.8);
        return t.play();
    };

    var close = function(){
        closed = true;
        var t = new TimelineMax({paused:true});
        if(disableCloseAnim)
            return t.play();

            t.to($home, 0.5, {opacity:0, ease:Linear.easeNone}, 0);
        return t.play();
    };

    var attach = function(){
        detach();
        viewport.on('resize', resize);
        $body.on('buildAnimations', buildAnimations);
        $gridItem.on('click', openProject);
    };
    var detach = function(){
        viewport.off('resize', resize);
        $body.off('buildAnimations', buildAnimations);
        $gridItem.off('click', openProject);
    };

    attach();

    var _public = {
        open:open,
        close:close,
        attach:attach,
        detach:detach
    }

    return _public;
};

module.exports = home;
