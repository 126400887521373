var env      = require('./env.js');
var bindable = require('./bindable.js');
var $        = require('jquery');

var viewport = (function(){
    
    var _html = document.getElementsByTagName('body')[0],
        _medias = [],
        _orientationValue = 0;
        
    var _init = function(){
        _registerEvents();
        _compute();
        _public.orientation = _public.width < _public.height?'portrait':'landscape';
        _orientationValue = window.orientation?window.orientation:0;
    };
    
    var _registerEvents = function(){
        window.addEventListener('resize', _resize);
        window.addEventListener('orientationchange', _resize);
    };
    
    var _compute = function(){
        _public.windowWidth = window.innerWidth;
        _public.windowHeight = window.innerHeight;
        
        _public.width =  _public.windowWidth < _public.minWidth ? _public.minWidth : _public.windowWidth;
        _public.height = _public.windowHeight < _public.minHeight ? _public.minHeight : _public.windowHeight;


        //Orientation
        if(typeof window.orientation == "number"
        && _orientationValue != window.orientation){
            _orientationValue = window.orientation;
            _public.orientation = _public.orientation == 'landscape'?'portrait':'landscape';
        }
    };
    
    var _resize = function(){
        var previousWidth = _public.width;
        var previousHeight = _public.height;

        _compute();
        _update();
        if(env.device.touch) {
            // if(previousWidth != _public.width){
                 // _resize();
                _triggerResize();
            // }
        } else {
            if(previousWidth != _public.width || previousHeight != _public.height){
                _resize();
            }
            _triggerResize();
        }
    };

    var _triggerResize =function(){
        _public.trigger('resize');
        _public.trigger('post-resize');
    };
    
    var _addClass = function(dom, className){
        var classes = (className || "").match(/\S+/g) || [];
        var cur = dom.nodeType === 1 && (dom.className ? (" " + dom.className + " ").replace(/[\t\r\n\f]/g, " ") : " ");
        if (cur) {
            var j = 0, clazz;
            while ((clazz = classes[j++])) {
                if (cur.indexOf(" " + clazz + " ") < 0) {
                    cur += clazz + " ";
                }
            }
            dom.className = cur.replace(/\s+/g, ' ');
        }
    };
    
    var _removeClass = function(dom, className){
        dom.className = dom.className.replace(new RegExp("\\s+"+className+"\\s+"),' ');
    };
    
    var _update = function(){
        for(var i=0; i<_medias.length; i++)
            _medias[i].update();
    };
    
    var _enableMedia = function(m){
        if(m.addClass)
            _addClass(_html, m.classPrefix + m.name);
        if(m.meta){
            // $('meta[name="viewport"]').attr('content', "width="+m.meta+'');
            $('head meta[name="viewport"]').remove();
            $('head').append('<meta name="viewport" content="width='+m.meta+', user-scalable=0">');
            _compute();
        }
        _public.medias[m.name] = true;
        _public.on(m.name, m);
        _public.on('media', m);
    };
    
    var _disableMedia = function(m){
        _public.medias[m.name] = false;
        if(m.addClass){
            _removeClass(_html, m.classPrefix + m.name);
        }
    };
    
    var _add = function(options){
        var m = viewport.media(options);
            m.on('disable', function(){
                _disableMedia(m);
            });
            m.on('enable', function(){
                _enableMedia(m);
            });
        _medias.push(m);


        for(var i=_medias.length-1; i>=0; i--){
             _public.medias[_medias[i].name] = _medias[i].active;   
             _public._medias[_medias[i].name] = _medias[i]; 
        }
           
            
    };
    
    
    var _public = bindable({
        orientation: null,
        windowWidth: 0,
        windowHeight: 0,
        minWidth: 0,
        minHeight: 0,
        width: 0,
        height: 0,
        resize: _resize,
        add:_add,
        medias:{},
        _medias: {}
    });
    
    _init();
    return _public;
})();

viewport.media = (function(options){
    var _options = $.extend({
        name:null,
        minWidth:-Infinity,
        minHeight:-Infinity,
        maxWidth:+Infinity,
        maxHeight:+Infinity,
        enable:null,
        disable:null,
        addClass:true,
        classPrefix:'',
        condition:null,
        meta:null
    }, options);
    
    var _init = function(){
        if(_options.disable)
            _public.on('disable', _options.disable);
        if(_options.enable)
            _public.on('enable', _options.enable);
    };
    
    var _update = function(){
        var active =    viewport.width >= _options.minWidth
                     && viewport.width < _options.maxWidth
                     && viewport.height >= _options.minHeight
                     && viewport.height < _options.maxHeight;
        if(typeof _options.condition == "function"){
            active = _options.condition(active);
        }
        if(_public.active && !active){
            _public.active = false;
            _public.on('disable');
            return true;
        }
        else if(!_public.active && active){
            _public.active = true;
            _public.on('enable');
            return true;
        }
        return false;
    };
    
    var _public = bindable({
        active:false,
        name:_options.name,
        meta:_options.meta,
        addClass:_options.addClass,
        classPrefix:_options.classPrefix,
        update:_update
    });
    
    _init();
    return _public;
});
window["viewport"] = viewport;
module.exports = viewport;