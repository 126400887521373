var $           = require('jquery');
var gsap        = require('gsap');
require('gsap/ScrollToPlugin');
var viewport    = require('./tools/viewport');
var env         = require('./tools/env');

var snapTimer;
var snapTm;
var snapping = false;

var $scrollpane = $('html');

if(env.platform.ios){
    $scrollpane = $('body');
}

var killSnap = function(){
    if(snapTm) snapTm.kill();
    snapping = false;
}

var snapTry = function(){
    clearTimeout(snapTimer);
    snapTimer = setTimeout(snap, 250);
};

var snap = function(){
    clearTimeout(snapTimer);

    var scrollTop = $scrollpane.scrollTop();
    var snapTo = null;

    var halfH = window.innerHeight/2;
    $('.fullscreen:not(.notreally)').each(function(){
        var item = $(this);
        var delta = scrollTop - item.offset().top;
        if(delta > -halfH && delta < halfH){
            snapTo = item;
        }
    });

    if(snapTo !== null){
        snapping = true;
        snapTm = TweenMax.to($scrollpane, 1.5, {scrollTo: snapTo.offset().top, ease:Quint.easeInOut, onComplete:function(){
            snapping = false;
        }});
    }
}

$(function(){
    viewport.on('resize', snapTry);
    $(window).on('scroll', snapTry);

    if(env.device.touch){
        $(window).on('touchstart', killSnap);
    }
})
