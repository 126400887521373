var $           = require('jquery');
var tools       = require('../tools/tools');
var viewport    = require('../tools/viewport');
var animations  = require('../animations');
var scroll    = require('../scroll');


var home = function($about){
    var $body       = $('body');
    var $title  = $about.find('.title-bloc h1');
    var $firstImage  = $about.find('.image-bloc.first');
    var $otherImage  = $about.find('.image-bloc:not(.first)');

    var $part5       = $about.find('.part5');
    var $p5Address       = $about.find('.address');
    var $p5Details       = $about.find('.details');


    var buildAnimations = function(e, scroller){
        var $reveals = $about.find('.part3 .text, .part3 .people, .clients-bloc .title');
        $reveals.each(function(){
            var item = $(this);
            scroll.reveal(item, {
                repeat:-1
            });
        });

        var $opacity = $about.find('.clients-bloc .image, .image-bloc:not(.first)');
        $opacity.each(function(){
            var item = $(this);
            scroll.reveal(item, {
                y: 0,
                repeat:-1
            });
        });

        //Part 2
        var h = viewport.height;
        scroll.timeline.fromTo($firstImage.find('img'), h + $firstImage.height(), {scale:1}, {scale:1.2, force3D:true, ease:Linear.easeNone}, 0);

        //Part 4
        scroll.timeline.fromTo($otherImage.find('img'), h + $otherImage.height(), {scale:1}, {scale:1.2, force3D:true, ease:Linear.easeNone}, $otherImage.offset().top-viewport.height);

        var timeline = new TimelineMax({paused: true});
        timeline.fromTo($p5Address, 1, {y: 40}, {y: 0, force3D: true, ease: Expo.easeOut}, 0);
        timeline.fromTo($p5Address, 0.5, {alpha: 0}, {alpha: 1, ease: Linear.easeNone},0);
        timeline.fromTo($p5Details  , 1, {y: 40}, {y: 0, force3D: true, ease: Expo.easeOut}, 0.2);
        timeline.fromTo($p5Details  , 0.5, {alpha: 0}, {alpha: 1, ease: Linear.easeNone},0.2);
        scroll.animate($part5, timeline, {offset:$part5.height()/3, repeat:-1, direction : 1});
    };

    var open = function(){

        var t = new TimelineMax({paused:true});
            t.fromTo($about, 0.7, {alpha: 0}, {alpha: 1, ease: Linear.easeNone}, 0);
            t.fromTo($title, 1, {y: 40}, {y: 0, force3D: true, ease: Expo.easeOut}, 0.6);
            t.fromTo($title, 0.5, {alpha: 0}, {alpha: 1, ease: Linear.easeNone},0.6);
            t.fromTo($firstImage, 0.5, {alpha: 0}, {alpha: 1, ease: Linear.easeNone},0.8);
        return t.play();
    };
    var close = function(){
        var t = new TimelineMax({paused:true});
            t.to($about, 0.5, {opacity:0, ease:Linear.easeNone}, 0);
        return t.play();
    };

    var resize = function(){
    };

    var attach = function(){
        detach();
        viewport.on('resize', resize);
        $body.on('buildAnimations', buildAnimations);
    };
    var detach = function(){
        viewport.off('resize', resize);
        $body.off('buildAnimations', buildAnimations);
    };

    attach();

    var _public = {
        open:open,
        close:close,
        attach:attach,
        detach:detach
    }

    return _public;
};

module.exports = home;
