var $    = require('jquery');
var gsap = require('gsap');
var viewport = require('./tools/viewport');

var fpsScrollAnimation = ( function() {

    var self = this;
    this.scrollY=0;
    this.smoothedScrollY=0;
    this.userScroll = true;
    this.isScrolling = false;
    this.scrollDirection =  0;
    this.rendering = true;
    var cache = {};
    var positions = {};
    var oldScrollY;
    var buildFn;
    this.init = function(fn){
        buildFn = fn;
        _initCache();
        this.initialized = true;
        registerEvents();
        onScroll();
    };

    var _initCache = function(){
        cache.win = $(window);
        cache.scrollCtn = $('html, body');
    };

    var registerEvents = function(){
        $(window).off("scroll").on("scroll", onScroll);

        this.layout();
        renderLoop();
    };

    this.layout = function(){
        if(!self.initialized)
            return;

        positions = {};
        self.timeline = new TimelineMax({paused: true, useFrames: true});
        self.timeline.addCallback(function(){}, max());
        self.scrollDirection = 0;


        if(buildFn) buildFn();
        cache.scrollCtn.trigger('buildAnimations');


        $.each(positions, function(position, items){
            var isScrollAfterItem = self.scrollY >= parseInt(position);
            //On fixe les valeurs initiale en fonction de isScrollAfterItem
            $.each(items, function(){
                var data = this.data('__animData');
                data.timeline.render(isScrollAfterItem?data.timeline.totalDuration():data.shift, false);
            });
        });


        self.smoothedScrollY = self.scrollY;

        self.timeline.render(self.smoothedScrollY, true, true);
    };

    var max = function(){
        return Math.max(0, $(document).outerHeight() - viewport.windowHeight);
    };

    var onScroll = function(){
        if(self.userScroll)
            self.scrollY = Math.max(document.documentElement.scrollTop, document.body.scrollTop);
    };

    var currentScrollTo;
    this.scrollTo = function(to){
        if(currentScrollTo)
            currentScrollTo.kill();

        var duration = Math.min(Math.max(Math.abs(to - fpsScrollAnimation.scrollY)/viewport.windowHeight, 1), 2);
        fpsScrollAnimation.userScroll = false;
        currentScrollTo = TweenMax.to(self, duration, {scrollY:to, ease:Quint.easeInOut, onComplete:function(){
            fpsScrollAnimation.userScroll = true;
        }});
    };

    var delta = 0;
    var time  = $.now()/1000;
    var lastTime = time;
    var render = function(){

        time     = $.now()/1000;
        delta    = time - lastTime;
        lastTime = time;
        // if(!self.userScroll)
        //  cache.scrollCtn.scrollTop(self.scrollY);

        self.isScrolling = true;
        var diff = self.scrollY - oldScrollY | 0;
        self.scrollDirection = Math.abs(diff)/diff | 0;

        self.smoothedScrollY += (self.scrollY - self.smoothedScrollY) * delta * 10;


        if( self.smoothedScrollY == oldScrollY){
            self.isScrolling = false;
            return;
        }
        if(Math.abs(self.scrollY - self.smoothedScrollY) < 0.1)
            self.smoothedScrollY = self.scrollY;

        self.timeline.seek( self.smoothedScrollY, false);
        oldScrollY =  self.smoothedScrollY;
    };

    var renderLoop = function(){
        if(self.rendering){
            window.requestAnimationFrame(renderLoop);
            render();
        }
    };

    var callback = function(opts){
        if(this.__replayCount < opts.repeat || opts.repeat == -1 ){
            if(self.scrollDirection == opts.direction || opts.direction == 0){
                this.__replayCount++;
                this.play(0);
            }
            else{
                this.pause().seek(0);
            }
        }
    };

    this.call = function(item, callback, options){
        options = $.extend({
            params: [],
            scope: null,
            offset:0, //Debut de l'animation par rapport au bas de l'ecran (en pixel)
            position: getPosition(item, (options && options.offset)?options.offset:0)
        }, options);

        self.timeline.addCallback(callback, options.position, options.params, options.scope);
    };

    this.animate = function(item, timeline, options){
        if(!item.hasClass('fullscreen')) item.removeAttr('style');
        // timeline.render(timeline.totalDuration(), false);
        options = $.extend({
            offset:0, //Debut de l'animation par rapport au bas de l'ecran (en pixel)
            direction : 0, // 0 : toutes direction
            repeat: -1, // repetition de l'animation
            position: getPosition(item, (options && options.offset)?options.offset:0)
        }, options);


        if(item.offset().top - viewport.height <= 0){
            return;
        }

        var pos = options.position;
        if(!positions[pos]){
            positions[pos] = [];
        }
        positions[pos].push(item);

        timeline.__replayCount = 0;

        item.data('__animData', {
            position: pos,
            timeline : timeline,
            options : options,
            shift:0
        });

        self.timeline.addCallback(callback, pos, [options], timeline);
    };
    
    this.reveal = function(item, options){

        options = $.extend({
            y: 40,
            x:0,
            rotation:0,
            scale:1,
            alpha: 0,
            delay:0, //decalage du demarrage de l'anim (en seconde)
            ease: Expo.easeOut,
            position: getPosition(item, (options && options.offset)?options.offset:0),
            duration: 1,
            direction: 1,
            repeat: 1
        }, options);

        var timeline = new TimelineMax({paused: true, delay : options.delay});
            timeline.fromTo(item, options.duration, {
                rotation: options.rotation+'deg',
                x: options.x,
                y: options.y,
                scale:options.scale
            }, {
                rotation: 0,
                x: 0,
                y: 0,
                scale:1,
                force3D: true,
                ease: options.ease
            }, 0);
            timeline.fromTo(item, options.duration/2, {alpha: options.alpha}, {alpha: 1, ease: Linear.easeNone},0);

        self.animate(item, timeline, options);
    };

    this.stagger = function(items, delay){
        delay = typeof delay == "undefined"?0.1:delay;
        var staggered = {}; // Stocke le nbr de stagger traité pour une posiiton
        items.each(function(){
            var item = $(this);
            var data = item.data("__animData");
            if(data){
                if(!staggered[data.position])
                    staggered[data.position] = 0;
                var shift = staggered[data.position]*delay;
                data.timeline.shiftChildren(shift, true);
                staggered[data.position]++;
                data.shift = shift;
                item.data("__animData", data);
            }
        });
    };

    //si item est un nombre on s'en sert comme position sinon on prend la position de l'item
    var getPosition = this.getPosition = function(item, offset){
        offset = offset?offset:0;
        var top = (!isNaN(parseFloat(item)) && isFinite(item))?item:item.offset().top;
        return Math.max(0, parseInt(top)+offset - viewport.windowHeight);
    };

    this.isAfter = function(item){
        return self.scrollY > getPosition(item);
    };




    return this;
})();


fpsScrollAnimation.init();

viewport.on('post-resize', fpsScrollAnimation.layout);

module.exports = fpsScrollAnimation;
