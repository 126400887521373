var $         = require('jquery');
var Rectangle = require('./math/rectangle');

var isJQuery = function(obj) {
    return obj instanceof $ && obj.length > 0;
};

var getBounds = function(ratio, reference, inside, max){
    var bounds = Rectangle(0, 0, reference.width, reference.height);

    max = $.extend({
        top:0,
        left:0,
        bottom:1,
        right:1
    }, max);

    var maxRatio = ((max.right - max.left)*reference.width) / ((max.bottom - max.top)*reference.height) | 0;
    var refRatio  = reference.width/reference.height;

    // Scale the image
    if (inside ? (refRatio >= ratio) : (refRatio < ratio)) {
        bounds.width  = reference.height * ratio | 0;
        bounds.x      = (reference.width - bounds.width)/2 | 0;

    }
    else{
        bounds.height = reference.width / ratio | 0;
        bounds.y      = (reference.height - bounds.height)/2 | 0;
    }

    if(!inside){
        var dx  = max.right * bounds.width - max.left * bounds.width;
        if(dx < reference.width && dx > 0){
            bounds.width *= reference.width / dx;
            bounds.height = bounds.width / ratio | 0;
        }

        var dy = max.bottom * bounds.height - max.top * bounds.height;
        if(dy < reference.height && dy > 0){
            bounds.height *= reference.height / dy;
            bounds.width  = bounds.height * ratio | 0;
        }

        bounds.x      = -max.left * bounds.width - ((max.right - max.left) * bounds.width - reference.width)/2;
        bounds.y      = -max.top * bounds.height - ((max.bottom - max.top) * bounds.height - reference.height)/2;
    }


    return bounds;
}

var getDataTransform = function(options, bounds){
    var data = {};
    data["force3D"] = true;
    if(bounds.width && !options.heightOnly){
        if(!options.sizeOnly && !options.widthOnly)
            data["x"] = bounds.x;
        data["width"] = bounds.width;
    }
    if(bounds.height && !options.widthOnly){
        if(!options.sizeOnly && !options.heightOnly)
            data["y"] = bounds.y;
        data["height"] = bounds.height;
    }
    return data;
};

var getData = function(options, bounds){
    var data = {};
    if(bounds.width && !options.heightOnly){
        if(!options.sizeOnly && !options.widthOnly)
            data["left"] = bounds.x;
        data["width"] = bounds.width;
    }
    if(bounds.height && !options.widthOnly){
        if(!options.sizeOnly && !options.heightOnly)
            data["top"] = bounds.y;
        data["height"] = bounds.height;
    }
    return data;
};

var fit = function(elements, options) {
    elements = $(elements);
    options = $.extend({
        inside:typeof options == "boolean"?options:false,
        animate:false,
        duration:1,
        ease:Quint.easeInOut,
        reference:null,
        ratio:null,
        useTransform:false,
        sizeOnly:false,
        heightOnly:false,
        widthOnly:false
    }, options);

    var t = new TimelineMax({paused:true});

    elements.each(function() {
        var item = $(this),
            $reference = options.reference?options.reference:item.parent();
        var data = item.data();

        var ratio = options.ratio;

        if(!ratio){
            if(data.ratio)
                ratio = data.ratio;
            else if(data.width && data.height)
                ratio = data.width/data.height;
        }

        var reference = isJQuery($reference)?Rectangle(0, 0, $reference.outerWidth(), $reference.outerHeight()):options.reference;

        var max = {
            top:data.maxTop,
            left:data.maxLeft,
            bottom:data.maxBottom,
            right:data.maxRight
        };

        if(options.hasOwnProperty('maxTop'))
            max.top = options.maxTop;
        if(options.hasOwnProperty('maxLeft'))
            max.left = options.maxLeft;
        if(options.hasOwnProperty('maxRight'))
            max.right = options.maxRight;
        if(options.hasOwnProperty('maxBottom'))
            max.bottom = options.maxBottom;

        var bounds = getBounds(ratio, reference, options.inside, max);
        var data = {};

        if(options.useTransform){
            data = getDataTransform(options, bounds);
        }
        else{
            data = getData(options, bounds);
        }
        if(options.animate){
        	data["ease"] = options.ease;
            t.to(item, options.duration, data, 0);
        }
        else TweenMax.set(item, data, 0);
    });
    
    return t.play();
};

module.exports = fit;