var $ 	    = require('jquery');
var fit      = require('../tools/fit');
require('../libs/jquery.preload');

var sliderzoom = function($container){
    var $body = $('body');
    var $base = $('base');
    var baseURL = $base.attr('href');
    var $zoom = $body.find('.slider-zoom');
    var $zoomClose = $zoom.find('.close');
	var $zoomPrevious = $zoom.find('.previous');
    var $zoomNext     = $zoom.find('.next');
    var $zoomSlider = $zoom.find('.slider-wrapper');
	var $zoomSlides = false;
	var $zoomHR = $zoomSlider.find('.hr');


	var $slides = $container.find('.slide:not(.slick-cloned)');

    var slickIsInit = false;

	var initSlick = function(e){
		var currentPos = $(e.currentTarget).attr('data-slick-index');
		if(currentPos > $slides.length) {
			currentPos = currentPos-$slides.length;
		}

		if(slickIsInit) {
			$zoomSlider.slick('slickGoTo', currentPos);
		} else {
			var html = '';

			//Create Slider
			$slides.each(function(){
				var img = $(this).data('image');
                var ratio = $(this).data('ratio');


				if(!env.device.mobile) {
					var src = baseURL+'uploads/gallery/thumb/'+img;
					var hr_src = baseURL+'uploads/gallery/'+img;
				} else {
					var src = baseURL+'uploads/gallery/thumb/mobile/'+img;
					var hr_src = baseURL+'uploads/gallery/mobile/'+img;
				}

				html += '<div class="slide" data-image="'+img+'" data-ratio="'+ratio+'">';
				html += '<div class="default"><img data-ratio="'+ratio+'" src="'+src+'" /></div>';
				html += '<div class="hr"></div>';
				html += '</div>';
			});

			$zoomSlider.html(html);

			$zoomSlider.slick({
              arrows: true,
              infinite: false,
              speed: 600,
			  slidesToShow: 1,
              prevArrow: $zoomPrevious,
              nextArrow: $zoomNext
            });

            $zoomSlider.find('.slide').each(function(){
                var img = $(this).find('.default img');
                img.css('margin-left', -(img.width()/2));
            });

			$zoomSlides = $zoomSlider.find('.slide');
			$zoomSlider.slick('slickGoTo', currentPos);


			setTimeout(function() {
                resize();
				$zoomSlider.on('afterChange', function(event, slick, currentSlide) {
				  	loadHR();
				});
			}, 600);

			slickIsInit = true;
		}
    };

	var loadHR = function(){
		if(!slickIsInit) return;

		var currentIndex = $zoomSlider.slick('slickCurrentSlide');
		var currentSlide = $zoomSlides.filter('[data-slick-index="'+currentIndex+'"]');
		var currentSlideHr = currentSlide.find('.hr');
		var filename = currentSlide.data('image');
        var ratio = currentSlide.data('ratio');

		if(currentSlide.hasClass('loaded')) return;

		var urls = [];
        var url = baseURL+'uploads/gallery/';
        if(env.device.mobile){ url += 'mobile/'; }
        url += filename;

        urls.push(url);

		$.preload(urls, {
            onFinish:function(){
                var img = document.createElement("img");
                img.src = url;
                img.dataset.ratio = ratio;
                var $img = $(img);
				currentSlideHr.html($img);

                $img.css('margin-left', -(currentSlide.find('.default img').width()/2));

				currentSlide.addClass('loaded');
            }
        });
	}

    var open = function(e){
        openAnim();
		initSlick(e);
    };

    var close = function(){
        closeAnim();
    };

    var openAnim = function () {
        $body.addClass('prevent-scroll');

        var tl = new TimelineMax();

        tl.fromTo($zoom.show(), 0.5, {alpha: 0}, {alpha: 1, ease: Linear.easeNone}, 0);
        tl.fromTo($zoomSlider, 0.6, {alpha: 0}, {alpha: 1, ease: Linear.easeNone}, 0.3);

		tl.addCallback(function () {
            loadHR();
        });

        return tl;
    };

    var closeAnim = function () {
        $body.removeClass('prevent-scroll');

        var tl = new TimelineMax();

        tl.to($zoom, 0.4, {alpha: 0, ease: Linear.easeNone}, 0.1);
        tl.to($zoomSlider, 0.4, {alpha: 0, ease: Linear.easeNone}, 0);

        tl.addCallback(function () {
            $zoom.hide();
        });

        return tl;
    };

    var resize = function(){
		if(slickIsInit) $zoomSlider.slick('resize');

        if(!$zoomSlides) return;
        $zoomSlides.find('img').each(function(){
            var img = $(this);
            img.css('margin-left', -(img.width()/2));
        });
    };

    var destroy = function(){
        detach();

		if(slickIsInit) {
            $zoomSlider.slick('destroy');
            $zoomSlider.html('');
            slickIsInit = false;
        }
    };

    var attach = function(){
        viewport.on('resize', resize);
        $slides.on('click', open);

        if(env.device.touch) {
            $zoomClose.on('touchstart', close);
        } else {
            $zoomClose.on('click', close);
        }
    };

    var detach = function(){
        viewport.off('resize', resize);
        $slides.off('click', open);

        if(env.device.touch) {
            $zoomClose.off('touchstart', close);
        } else {
            $zoomClose.off('click', close);
        }
    };

    attach();

    var _public = {
        attach:attach,
        detach:detach,
        destroy: destroy
    };

    return _public;
};

module.exports = sliderzoom;
