var $ 	    = require('jquery');

var types = {
	youtube: require('../tools/video-youtube'),
	vimeo: require('../tools/video-vimeo')
};

var video = function($container){

	var type = $container.data("type");
	var id   = $container.data("id");

	if(types.hasOwnProperty(type)){
		return types[type]($container);
	}
	else{
		console.error("Video type ["+type+"] not found.");
	}
	return null;
};

module.exports = video;