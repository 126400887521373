var $   = require('jquery');


window[$] = $;
var tools  = require('../tools/tools');
var fit    = require('../tools/fit');
var viewport = require('../tools/viewport');
var Rectangle = require('../tools/math/rectangle');

var sheet = tools.createStyleSheet();

var colorRules = {};
var addColor = function(color){
    if(!colorRules.hasOwnProperty(color)){
        sheet.insertRule('[data-color="'+color+'"] { color:'+color+'; }', 0);
    }
};


var card = function($card){
    var $mask       = $card.find('.mask');
    var $imgLight   = $mask.find('.image');

    addColor($card.data('color'));

    var fromFullscreen = function(useMax){

        var h       = viewport.height,
            x       = -$mask.offset().left,
            y       = -$mask.offset().top,
            width   = viewport.width,
            height  = viewport.height;

        var t = new TimelineMax({paused:true, useFrames:true});
        //On rajoute l'anim qui fit l'image vers la taille actuelle (petite)
        if(useMax === false){
            t.add(fit($imgLight, {
                animate:true,
                duration:h,
                ease:Linear.easeNone,
                useTransform:true,
                maxTop:0,
                maxBottom:1,
                maxLeft:0,
                maxRight:1
            }), 0);
        }
        else{
            t.add(fit($imgLight, {
                animate:true,
                duration:h,
                ease:Linear.easeNone,
                useTransform:true
            }), 0);
        }


        //On rajoute l'anim qui remet la taille actuelle du mask (petite)
        t.fromTo($mask, h, {
            x:x,
            y:y,
            width:width,
            height:height
        },
        {
            x:0,
            y:0,
            width:$mask.outerWidth(),
            height:$mask.outerHeight(),
            force3D:true,
            ease:Linear.easeNone
        }, 0);
        //On fit l'image actuelle au cas où le mask ai changé (au dessus)
        t.add(fit($imgLight, {
            reference:Rectangle(0,0,viewport.width, viewport.height),
            useTransform:true,
            maxTop:$imgLight.data('maxTop'),
            maxBottom:$imgLight.data('maxBottom'),
            maxLeft:$imgLight.data('maxLeft'),
            maxRight:$imgLight.data('maxRight')
        }), 0);


        return t.play();
    };

    var toFullscreen = function(){
        var x       = -$mask.offset().left,
            y       = -$mask.offset().top,
            width   = viewport.width,
            height  = viewport.height;

        var t = new TimelineMax({paused:true, useFrames:true});

        //On rajoute l'anim qui remet la taille de actuelle du mask (petite)
        t.fromTo($mask, 1.5, {
            x:0,
            y:0,
            width:$mask.outerWidth(),
            height:$mask.outerHeight()
        }, {
            x:x,
            y:y,
            width:width,
            height:height,
            force3D:true,
            ease:Expo.easeInOut
        }, 0);

        t.to($imgLight.find('img'), 1.5, {x:0, y:0, force3D:true, ease:Expo.easeInOut}, 0);
        //On fit l'image actuelle au cas où le mask ai changé (au dessus)
        t.add(fit($imgLight, {
            ease:Expo.easeInOut,
            animate:true,
            duration:1.5,
            reference:Rectangle(0,0, width, height),
            useTransform:true,
            maxTop:$imgLight.data('maxTop'),
            maxBottom:$imgLight.data('maxBottom'),
            maxLeft:$imgLight.data('maxLeft'),
            maxRight:$imgLight.data('maxRight')
        }), 0);

        return t.play();
    };

    var resize = function(){
        $mask.removeAttr('style');
        $imgLight.removeAttr('style');
    };

    var _public = {
        fromFullscreen:fromFullscreen,
        toFullscreen:toFullscreen,
        attach:function(){
            viewport.on('resize', resize);
        },
        detach:function(){
            viewport.off('resize', resize);
        }
    };

    return _public;
};

module.exports = card;