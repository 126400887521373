var Point = (function(x, y){
    var _public = {
        x:0,
        y:0
    };

    var _set = function(x, y){
        _public.x = x === undefined?_public.x:x;
        _public.y = y === undefined?_public.y:y;
        return _public;
    };

    var _add = function(p) {
        return Point(_public.x + p.x, _public.y + p.y);
    };

    var _subtract = function(p){
        return Point(_public.x - p.x, _public.y - p.y);
    };

    var _multiply = function(p){
        return Point(_public.x * p.x, _public.y * p.y);
    };

    var _divide = function(p){
        return Point(_public.x / p.x, _public.y / p.y);
    };

    var _negate = function() {
        return Point(-_public.x, -_public.y);
    };

    var _dot = function(p) {
        return _public.x * p.x + _public.y * p.y;
    };

    var _cross = function(p) {
        return _public.x * p.y - _public.y * p.x;
    };

    var _getLength = function() {
        return Math.sqrt(_dot(_public));
    };

    var _getAngle = function(p, o){
        if(!p){
            return Math.atan2(this.y, this.x);
        }
        if(!o){
            var div = _public.getLength() * p.getLength();
            if (div === 0)
                return NaN;

            var a = _public.dot(p) / div;
            return Math.acos(a < -1 ? -1 : a > 1 ? 1 : a);
        }
    };
    var _getDirectedAngle = function(p) {
        return Math.atan2(_public.cross(p), _public.dot(p));;
    };

    var _clone = function(){
        return Point(_public.x, _public.y);
    };

    var _normalize = function(length) {
        if (length === undefined)
            length = 1;
        var current = _public.getLength(),
            scale   = current !== 0 ? length / current : 0,
            point   = Point(_public.x * scale, _public.y * scale);
        return point;
    };


    _public.clone      = _clone;
    _public.set        = _set;
    _public.add        = _add;
    _public.subtract   = _subtract;
    _public.multiply   = _multiply;
    _public.divide     = _divide;
    _public.negate     = _negate;
    _public.dot        = _dot;
    _public.cross      = _cross;
    _public.getLength  = _getLength;
    _public.getAngle   = _getAngle;
    _public.getDirectedAngle = _getDirectedAngle;
    _public._normalize = _normalize;

    _set(x, y);

    return _public;
});
Point.TEMPORARY = Point();

Point.fromPolar = function(a, d){
    return Point(d*Math.cos(a), d*Math.sin(a));
};

module.exports = Point;