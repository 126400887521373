var $        = require('jquery');
var viewport = require('../tools/viewport');
var router   = require('../tools/router');
var responsive       = require('../responsive');
var tools    = require('../tools/tools');
var fit      = require('../tools/fit');
var Rectangle = require('../tools/math/rectangle');
var animations   = require('../animations');
var scroll    = require('../scroll');

window["$"] = $;

var menu = function($menu){
    var $body       = $('body');
    var $scrollTop   = $menu.find('.scroll-top');
    var $projects   = $menu.find('.project');
    var $projectsAnim = $projects.filter(':nth-child(3n+1), :nth-child(3n+3)');
    var $scrollpane = $menu.find('.scrollpane');
    var $gridSpinner = $menu.find('.loader');
    var disableCloseAnim = false;

    var grid = {current: 12, step: 12, isLoading: false};

    var loadGridItems = function(){
        if(grid.isLoading || scroll.scrollDirection <= 0) return;
        grid.isLoading = true;

        var end = grid.current + grid.step;
        var $nextItems = $projects.slice(grid.current, end);
        var imgs = [];

        $nextItems.each(function() {
            var img = $(this).find('.wrapper img');
            var src = img.data('desktop-src');

            if(viewport.medias.mobile) {
                src = img.data('mobile-src');
            }

            imgs.push(src);
        });

        if(imgs.length == 0) {
            $gridSpinner.remove();
            return;
        }

        $.preload(imgs, {
            onFinish:displayGridItems
        });
    };

    var displayGridItems = function(){
        var end = grid.current + grid.step;
        var $nextItems = $projects.slice(grid.current, end);

        var t = new TimelineMax({paused:true});
        var stagger = 0.1;
        var options = {
            y:0,
            duration:1.5
        };

        $nextItems.addClass('display');

        $nextItems.each(function(i) {
            var $item = $(this);
            var i3  = i/3 | 0;
            var pos = Math.abs(i%3-1)*stagger + stagger * i3;

            t.fromTo($item, options.duration/2, {alpha: 0}, {alpha: 1, ease: Linear.easeNone}, pos);
        });
        t.play();

        $nextItems.find('.wrapper img').addClass('responsive-src responsive-size');
        responsive.setup();
        viewport.resize();

        grid.current += grid.step;
        grid.isLoading = false;
    };

    var resize = function(){
        $projects.find('> .wrapper').removeAttr('style');
        $projects.removeAttr('style').addClass('hoverable');
        $scrollpane.removeAttr('style');

        $projects.find('.overlay .wrapper').each(function(){
            $overlay = $(this);
            var maxWidth = $overlay.width();
            $overlay.find('.title span').removeAttr('style').each(function(){
                fitSize($(this), maxWidth);
            });
        });
    };

    var fitSize = function(span, maxWidth, size){
        if(maxWidth < span.outerWidth()){
            size = parseFloat(span.css('font-size'))
            size--;
            span.css('font-size', size+'px');

            setTimeout(function(){
                fitSize(span, maxWidth, size);
            }, 10);
        }
    }

    var buildAnimations = function(e, scroller){

        var $lastGridItem = $scrollpane.find('.project.display').last();
        if($lastGridItem.length) scroll.call($lastGridItem, loadGridItems, {offset: $lastGridItem.outerHeight()/2});

        if(!viewport.medias.mobile) {
            TweenLite.set($scrollpane, {paddingBottom : 0});
            var y = Math.max(100, ($scrollpane.height()/100)*5);
            scroll.timeline.fromTo($projectsAnim, $scrollpane.height(), {y: 0}, {y: y, force3D: true}, scroll.getPosition($scrollpane)+50);
            var paddingTop = 160;
            var animRatio =  ($scrollpane.height()-(viewport.height-paddingTop)) / $scrollpane.height();
            var maxY = y*animRatio
            TweenLite.set($scrollpane, {paddingBottom : maxY+10});
        }

        scroll.timeline.fromTo($scrollTop, 0.5, {opacity: 0}, {opacity: 1, force3D: true}, $body.height()/2);
    };

    var scrollTop = function(){
        TweenMax.to($('html, body'), 2, {scrollTop:0, ease: Expo.easeOut});
    };


    var openProject = function(e){
        e.preventDefault();

        detach();

        var $project = $(this);

        router.change($project.find('a').attr('href'));
    };

    var open = function(){
        disableCloseAnim = false;

        $('html')[0].style.overflow = 'hidden';
        $body[0].style.overflow = 'hidden';

        var t = new TimelineMax({paused:true});

        var stagger = 0.1;
        var options = {
            y:80,
            duration:1.5
        };

        $projects.each(function(i){
            var $item = $(this);
            if($item.offset().top < ((window.oldScroll)? window.oldScroll : viewport.height)){
                var i3  = i/3 | 0;
                var pos = Math.abs(i%3-1)*stagger + stagger * i3;

                t.add(animations.reveal($item, $.extend({}, options, {
                    delay:pos
                })).play(), 0);
            }
        });

        if(window.oldScroll && window.oldScroll > 0) t.to($('html, body'), 2, {scrollTop:window.oldScroll, ease: Expo.easeOut}, 0);

        t.add(function(){
            $('html, body').removeAttr('style');
        });

        return t.play();
    };

    var close = function(){
        window.oldScroll = scroll.scrollY;
        var t = new TimelineMax({paused:true});
        if(disableCloseAnim)
            return t.play();

            var stagger = 0.05;
            var options = {
                y:80,
                duration:0.5,
                ease:Expo.easeIn,
                from:null
            };
            var max = $projects.length-1;
            $projects.each(function(i){
                var $item = $(this);

                if($item.offset().top < (window.oldScroll)? window.oldScroll : viewport.height){
                    var i3  = (max-i)/3 | 0;
                    var pos = Math.abs((max-i)%3-1)*stagger + stagger * i3;

                    t.add(animations.hide($item, options).play(), pos);
                }
            });
        return t.play();
    };

    var attach = function(){
        detach();

        viewport.on('resize', resize);
        $projects.on('click', openProject);
        $body.on('buildAnimations', buildAnimations);

        if(env.device.touch) {
            $scrollTop.on('touchstart', scrollTop);
        } else {
            $scrollTop.on('click', scrollTop);
        }
    };

    var detach = function(){
        viewport.off('resize', resize);
        $projects.off('click', openProject);
        $body.off('buildAnimations', buildAnimations);
        if(env.device.touch) {
            $scrollTop.off('touchstart', scrollTop);
        } else {
            $scrollTop.off('click', scrollTop);
        }
    };

    attach();

    var _public = {
        open:open,
        close:close,
        attach:attach,
        detach:detach
    }

    return _public;
};

module.exports = menu;
