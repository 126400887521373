var $           = require('jquery');
var slick       = require('../libs/slick.min');
var tools       = require('../tools/tools');
var viewport    = require('../tools/viewport');
var fit         = require('../tools/fit');
var animations  = require('../animations');
var scroll    = require('../scroll');

var Rectangle   = require('../tools/math/rectangle.js');

var router      = require('../tools/router');
var profile     = require('../profile');
var debounce = require('throttle-debounce').debounce;

var project = function($project){
    var $body       = $('body');
    var $zoomSlider      = $body.find('.slider-zoom');;
    var $scrollpane  = $project.find('> .scrollpane');

    var $top = $project.find('.top');
    var $poster = $top.find('.poster');
    var $info = $top.find('.info');
    var $infoSub = $info.find('.sub');
    var $infoTitle = $info.find('h1');
    var $infoText = $info.find('.text');

    var $next       = $project.find('.next-project');
    var $video      = $project.find('.video');

    var $gallery         = $project.find('.gallery');
    var $galleryPrevious = $gallery.find('.previous');
    var $galleryNext     = $gallery.find('.next');
    var $gallerySP       = $gallery.find('.slider');
    var $slides          = $gallery.find('.slide');

    var $tags       = $project.find('.tags a');

    var isLight   = $project.hasClass('light');

    var $currentFullscreen = null;

    var skipped = false;
    var attached = false;
    var disableCloseAnim = false;
    var previousProfile = null;

    var slickIsInit = false;

    var init = function() {
        initSlider();
    };

    var initSlider = function() {
        if(!slickIsInit) {
            slickIsInit = true;

            $gallerySP.slick({
              arrows: true,
              infinite: true,
              speed: 400,
              variableWidth: true,
              prevArrow: $galleryPrevious,
              nextArrow: $galleryNext
            });
        }
    };

    var buildAnimations = function(e, scroller){
        var $reveals = $project.find('.block.text, .gallery .wrapper .label, .tags');
        $reveals.each(function(){
            var item = $(this);
            scroll.reveal(item, {
                repeat:-1
            });
        });

        var $opacity = $project.find('.block.image, .video, .gallery .slider');
        $opacity.each(function(){
            var item = $(this);
            scroll.reveal(item, {
                y: 0,
                repeat:-1
            });
        });

        var $nextP = $project.find('.next-project');
        if($nextP.length > 0) {
            var timeline = new TimelineMax({paused: true});
            timeline.fromTo($nextP.find('.title'), 1, {y: 40}, {y: 0, force3D: true, ease: Expo.easeOut}, 0);
            timeline.fromTo($nextP.find('.title'), 0.5, {alpha: 0}, {alpha: 1, ease: Linear.easeNone},0);
            timeline.fromTo($nextP.find('.subtitle'), 1, {y: 40}, {y: 0, force3D: true, ease: Expo.easeOut}, 0.2);
            timeline.fromTo($nextP.find('.subtitle'), 0.5, {alpha: 0}, {alpha: 1, ease: Linear.easeNone},0.2);
            timeline.fromTo($nextP.find('a'), 1, {y: 40}, {y: 0, force3D: true, ease: Expo.easeOut}, 0.5);
            timeline.fromTo($nextP.find('a'), 0.5, {alpha: 0}, {alpha: 1, ease: Linear.easeNone},0.5);
            scroll.animate($nextP, timeline, {offset:$nextP.height()/3, repeat:-1, direction : 1});
        }
    };

    var resize = function(){
        if(slickIsInit) $gallerySP.slick('resize');
    };

    var open = function(){
        disableCloseAnim = false;
        viewport.resize();
        var t = new TimelineMax({paused:true});
            t.fromTo($project, 0.7, {alpha: 0}, {alpha: 1, ease: Linear.easeNone}, 0);
            t.fromTo($poster, 0.5, {alpha: 0}, {alpha: 1, ease: Linear.easeNone},0.4);
            t.fromTo($infoSub, 1, {y: 40}, {y: 0, force3D: true, ease: Expo.easeOut}, 0.6);
            t.fromTo($infoSub, 0.5, {alpha: 0}, {alpha: 1, ease: Linear.easeNone},0.6);
            t.fromTo($infoTitle, 1, {y: 40}, {y: 0, force3D: true, ease: Expo.easeOut}, 0.8);
            t.fromTo($infoTitle, 0.5, {alpha: 0}, {alpha: 1, ease: Linear.easeNone},0.8);
            t.fromTo($infoText, 1, {y: 40}, {y: 0, force3D: true, ease: Expo.easeOut}, 1);
            t.fromTo($infoText, 0.5, {alpha: 0}, {alpha: 1, ease: Linear.easeNone},1);
            t.add(function(){
                init();
                resize();
            },0);
        return t.play();
    };

    var close = function(){
        var t = new TimelineMax({paused:true});
        if(disableCloseAnim)
            return t.play();

            t.to($project, 0.5, {opacity:0, ease:Linear.easeNone}, 0);
            t.add(function(){
                if($gallerySP.length > 0) $gallerySP.data('comp').destroy();
                if($poster.length > 0) $poster.data('comp').destroy();
            },0);
        return t.play();
    };

    var preventDefault = function(e){
        e.preventDefault();
    };

    var attach = function(){
        detach();
        viewport.on('resize', resize);
        $body.on('buildAnimations', buildAnimations);
    };

    var detach = function(){
        viewport.off('resize', resize);
        $body.off('buildAnimations', buildAnimations);
    };

    var _public = {
        open:open,
        close:close,
        attach:attach,
        detach:detach
    }

    return _public;
};

module.exports = project;
