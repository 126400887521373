var $           = require('jquery');
var gsap        = require('gsap');
var debounce    = require('throttle-debounce/debounce');
var animations  = require('../animations');
var router      = require('../tools/router');
var tools       = require('../tools/tools');
var profile     = require('../profile');

var search = function($search){

    var $input   = $search.find('input');
    var $form    = $search.find('form');
    var $tags    = $search.find('.tag');
    var $close   = $search.find('.close');
    var $results = $search.find('.results');
    var $resultsCount = $results.find('.count');
    var $placeholder  = $search.find('.placeholder');
    var $suggest      = $search.find('.suggest');
    var $background   = $search.find('.background');

    var closed = true;
    var lastSearch = tools.urlParam('text');
    var lastProfile = null;
    var currentCount = 0;

    tools.simulateTouchClick($search);

    var keypressed = function(e){
        var text = $input.val();

        if(text.length == 0){
            $placeholder.show();
            $suggest.hide();
        }
        else{
            $placeholder.hide();
            $suggest.show();
        }

        search(text);
    };

    var search = debounce(200, false, function(text){
        $.post(encodeURI('./search/q?text='+text)).done(function(response){
            var count = 0;
            if(response.status == "success"){
                if(response.results.project){
                    count = response.results.project.length;
                }
            }
            currentCount = count;
            setResults(count);
        });

        $.post(encodeURI('./search/q?tag='+text)).done(function(response){
            var tags = [];
            if(response.status == "success"){
                if(response.results.tag){
                    tags = response.results.tag;
                }
            }
            highlightTags(tags);
            suggestTags(tags);
        });
    });

    var submit = function(e){
        e.preventDefault();
        e.stopPropagation();
        if(lastSearch == $input.val()){
            close();
        }
        else{
            router.change('projects/q?text='+encodeURIComponent($input.val()));
            lastSearch = $input.val();
        }
        return false;
    };

    var highlightTags = function(tags){

        $tags.removeClass("active");
        $.each(tags, function(){
            $tags.filter('[data-id="'+this.id+'"]').addClass('active');
        });
    };

    var suggestTags = function(tags){
        var text = "";

        if(tags.length > 0){
            text = $tags.filter('[data-id="'+tags[0].id+'"]').find('span').html();
        }
        $suggest.html(text);
    };

    var setResults = function(count){
        $results.removeAttr('style');
        $resultsCount.html(count);

        $results.removeClass('one multiple');
        $results.addClass(count>1?'multiple':'one');
    };

    var open = function(){
        var t = new TimelineMax({paused:true});
        if(!closed) return t.play();

        lastProfile = profile.current;
        profile.set('black-on-white');

        $results.removeAttr('style');
        $search.show();
            t.fromTo($background, 1.5, {x:viewport.width}, {x:0, ease:Quint.easeInOut, force3D:true}, 0);
            t.add(animations.reveal($form, {
                x:60,
                y:0
            }).play(), 1);

            t.from($results, 0.5, {opacity:0}, 1.1);

            t.add(animations.reveal($tags, {
                x:0,
                y:0,
                ease:Linear.easeNone,
                duration:0.5
            }).play(), 1.1);

            t.add(animations.reveal($close, {
                x:0,
                y:0,
                ease:Linear.easeNone,
                duration:0.5
            }).play(), 1.2);


        closed = false;
        return t.play();
    };
    var close = function(){
        var t = new TimelineMax({paused:true});
        if(closed) return t.play();
        profile.set(lastProfile);
            t.to($background, 1.5, {x:viewport.width, ease:Quint.easeInOut, force3D:true}, 0);
            t.add(animations.hide($form, {
                x:60,
                y:0,
                duration:0.5,
                ease:Cubic.easeIn
            }).play(), 0);
            t.add(animations.hide($results, {
                x:60,
                y:0,
                duration:0.5,
                ease:Cubic.easeIn
            }).play(), 0);
            t.add(animations.hide($tags, {
                x:0,
                y:0,
                ease:Linear.easeNone,
                duration:0.5
            }).play(), 0);
            t.add(animations.hide($close, {
                x:0,
                y:0,
                ease:Linear.easeNone,
                duration:0.5
            }).play(), 0);
            t.add(function(){
                $search.hide();
            });
        closed = true;
        return t.play();
    };
    var attach = function(){
        $close.on('click', close);
        $form.on('submit', submit);
        $input.on('keyup keydown', keypressed);
    };
    var detach = function(){
        $close.off('click', close);
        $form.off('submit', submit);
        $input.off('keyup keydown', keypressed);
    };

    attach();

    var _public = {
        open:open,
        close:close,
        attach:attach,
        detach:detach
    };

    return _public;
};

module.exports = search;
