var $ = require('jquery');

var current = null;
var profile = {
	current:null,
	set:function(next){
		if(profile.current != next){
			$('body').removeClass(profile.current).addClass(next);
			profile.current = next;
		}
	}
};

module.exports = profile;