var $           = require('jquery');
var gsap        = require('gsap');
var viewport    = require('./tools/viewport');
var scroll = require('./scroll');
var routes      = require('./routes');
var responsive  = require('./responsive');
require('./snapper');

// var AppStats    = require('./tools/appstats');
// var Stats       = require('stats.js');
TweenLite.defaultEase = Linear.easeNone;


// var stats = new Stats();
//  stats.domElement.style.position = 'absolute';
//  stats.domElement.style.left = '0px';
//  stats.domElement.style.top = '0px';
// document.body.appendChild( stats.domElement );


// var render = function(){
//     window.requestAnimationFrame(render);
//     stats.update();
// }
// render();
