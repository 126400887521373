var $ 	    = require('jquery');
require('../libs/jquery.preload');

var zoom = function($container){
	var filename = $container.data("hr");
    var $body = $('body');
    var $base = $('base');
    var baseURL = $base.attr('href');
    var $zoom = $body.find('.zoom');
    var $zoomClose = $zoom.find('.close');
    var $zoomWrapper = $zoom.find('.img-wrapper');
	var loaderHtml = $zoomWrapper.html();

    var $currentZoom = false;
    var zoomHeight = false;
    var zoomWidth = false;
    var isVertical = false;

    var preloadZoom = function() {
        var urls = [];
        var url = baseURL+'uploads/project/hr/';
        if(env.device.mobile){ url += 'mobile/'; }
        url += filename;
        urls.push(url);

        $.preload(urls, {
            onFinish:function(){
                var img = document.createElement("img");
                img.src = url;

                var hw = Math.round(img.width/2);
                var hh = Math.round(img.height/2);

				$zoomWrapper.html(img);

                $currentZoom = $zoomWrapper.find('img');
                setZoom();

                //$zoomWrapper.on('mousemove', move);
            }
        });
    };

    var setZoom = function() {
        if(!$currentZoom) return;
        var screenH = $zoomWrapper.height();
        var screenW = $zoomWrapper.width();
        var screenRatio = screenW/screenH;
        var imgH = $currentZoom.height();
        var imgW = $currentZoom.width();
        var imgRatio = imgW/imgH;

        if((screenRatio < 1  && imgRatio < 1 && screenRatio < imgRatio) || (imgRatio > 1 && screenRatio < imgRatio)) {
            isVertical = true;
            $currentZoom.addClass('vertical');
            TweenMax.set($currentZoom, {y: 0});
        }  else {
            isVertical = false;
            $currentZoom.removeClass('vertical');
            TweenMax.set($currentZoom, {x: 0});
        }

        zoomHeight = $currentZoom.height();
        zoomWidth = $currentZoom.width();
    };

    var move = function(e) {
        var zoomer = e.currentTarget;

		if(env.device.touch) return;

        if(isVertical) {
            var ratioX = e.clientX/zoomer.offsetWidth;
            var maxMove = zoomWidth - zoomer.offsetWidth;
            var moveX = maxMove * ratioX;

            if(maxMove > 0) {
                TweenMax.set($currentZoom, {x: -moveX, force3D: true});
            }
        } else {
            var ratioY = e.clientY/zoomer.offsetHeight;
            var maxMove = zoomHeight - zoomer.offsetHeight;
            var moveY = maxMove * ratioY;

            if(maxMove > 0) {
                TweenMax.set($currentZoom, {y: -moveY, force3D: true});
            }
        }
    };

    var open = function(){
        openAnim();
        preloadZoom();
    };

    var close = function(){
        //$zoomWrapper.off('mousemove', move);
        closeAnim();
    };

    var openAnim = function () {
        $body.addClass('prevent-scroll');

        var tl = new TimelineMax();

        tl.fromTo($zoom.show(), 0.5, {alpha: 0}, {alpha: 1, ease: Linear.easeNone}, 0);
        tl.fromTo($zoomWrapper, 0.6, {alpha: 0}, {alpha: 1, ease: Linear.easeNone}, 0.3);
        tl.fromTo($zoomWrapper, 0.6, {y: 100}, {y: 0, force3D: true, ease: Expo.easeOut}, 0.3);

        return tl;
    };

    var closeAnim = function () {
        $body.removeClass('prevent-scroll');

        var tl = new TimelineMax();

        tl.to($zoom, 0.4, {alpha: 0, ease: Linear.easeNone}, 0.1);
        tl.to($zoomWrapper, 0.4, {alpha: 0, ease: Linear.easeNone}, 0);

        tl.addCallback(function () {
            $zoom.hide();
        });

        return tl;
    };

    var resize = function(){
        setZoom();
    };

	var destroy = function(){
		detach();
		$zoomWrapper.html(loaderHtml);
	};

    var attach = function(){
        viewport.on('resize', resize);
        $container.on('click', open);

		if(env.device.touch) {
            $zoomClose.on('touchstart', close);
        } else {
            $zoomClose.on('click', close);
        }
    };

    var detach = function(){
        viewport.off('resize', resize);
        $container.off('click', open);
		
		if(env.device.touch) {
            $zoomClose.off('touchstart', close);
        } else {
            $zoomClose.off('click', close);
        }

		//$zoomWrapper.off('mousemove', move);
    };

    attach();

    var _public = {
        attach:attach,
        detach:detach,
		destroy:destroy
    };

    return _public;
};

module.exports = zoom;
