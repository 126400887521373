var $       = require('jquery');
var components = {
    home: require('./components/home'),
    header: require('./components/header'),
    project: require('./components/project'),
    menu: require('./components/menu'),
    about: require('./components/about'),
    search: require('./components/search'),
    card: require('./components/card'),
    video: require('./components/video'),
    zoom: require('./components/zoom'),
    sliderzoom: require('./components/sliderzoom')
};

var scan = function(){
    $('[data-component]').each(function(){
        var $comp = $(this);
        if(!$comp.data('comp')){
            create($comp);
        }
    });
};

var create = function($item){
    var name = $item.data('component');
    if(components.hasOwnProperty(name)){
        if(name == "video"){
            var $elem = $item;
            setTimeout(function(){
                var comp = components[name]($elem);
                $elem.data('comp', comp);
            }, 2000);
            return null;
        }
        var comp = components[name]($item);
        $item.data('comp', comp);
        return comp;
    }
    if(console && console.error) console.error("Component not found: ", name);
    return null;
};

var _public = {
    scan:scan
};

module.exports = _public;
